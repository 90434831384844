import React, { useCallback, useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import useClientService from "../../../hooks/ClientService";
import useEntityService from "../../../hooks/EntityService";
import { EntityType, PeriodType } from "../../../types";
import { get, isEmpty, map, orderBy, zipObject } from "lodash";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

type EntityListProps = {
  entities: EntityType[];
};

const EntityList = ({ entities }: EntityListProps) => {
  if (isEmpty(entities)) {
    return (
      <Typography variant="body2" sx={{ fontStyle: "italic" }}>
        No entities defined
      </Typography>
    );
  } else {
    const orderedEntities = orderBy(entities, (entity) => entity.name);
    return (
      <List dense disablePadding>
        {map(orderedEntities, (entity) => (
          <ListItem key={entity.entityId} dense disablePadding>
            <ListItemText>{entity.name}</ListItemText>
          </ListItem>
        ))}
      </List>
    );
  }
};

type EntityHistoryProps = {
  organisationId: string;
  clientId: string;
};

const EntityHistory = ({ organisationId, clientId }: EntityHistoryProps) => {
  const clientService = useClientService();
  const entityService = useEntityService();

  const [periods, setPeriods] = useState<PeriodType[]>([]);
  const [periodEntities, setPeriodEntities] = useState<Record<string, EntityType[]>>({});

  const loadPeriods = useCallback(async () => {
    const results = await clientService.getPeriods(organisationId, clientId);
    setPeriods(results);
  }, [organisationId, clientId, clientService]);

  const loadPeriodEntities = useCallback(
    async (periodIds: string[]) => {
      const results = await Promise.all(
        map(periodIds, (periodId) => entityService.getEntities(organisationId, clientId, periodId))
      );
      setPeriodEntities(zipObject(periodIds, results));
    },
    [organisationId, clientId, entityService]
  );

  const getPeriodEntities = useCallback(
    (clientId: string): EntityType[] => {
      return get(periodEntities, clientId, []);
    },
    [periodEntities]
  );

  useEffect(() => {
    void loadPeriods();
  }, [loadPeriods]);

  useEffect(() => {
    const periodIds = map(periods, (period) => period.periodId);
    void loadPeriodEntities(periodIds);
  }, [periods, loadPeriodEntities]);

  return (
    <Grid container sx={{ mt: 2 }}>
      <Grid item xs={12} sx={{ m: 2 }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Entity history</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontStyle: "italic" }}>
              History of entities for each financial period over time.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ mx: 2 }}>
        <TableContainer component={Paper} square elevation={0}>
          <Table aria-label="History table" size="medium">
            <TableHead>
              <TableRow>
                <TableCell>Period</TableCell>
                <TableCell>Entities</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderBy(periods, (period) => period.name, "desc").map((period) => (
                <TableRow key={period.periodId}>
                  <TableCell>{period.name || "Unnamed Period"}</TableCell>
                  <TableCell>
                    <EntityList entities={getPeriodEntities(period.periodId)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default EntityHistory;
