import { yupResolver } from "@hookform/resolvers/yup";
import { InputAdornment } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FirebaseError } from "firebase/app";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import useUserService from "../../hooks/UserService";
import { VerificationMultiFactorAuth } from "../../types";
import ReAuthenticationDialog from "./ReAuthenticateDialog";

type StepIndex = 0 | 1;

const STEPS = ["Phone Number", "Verification"] as const;

const phoneSchema = yup
  .object({
    countryCode: yup.string().required("Country code is required"),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .matches(/^\d{1,14}$/, "Please enter a valid phone number"),
  })
  .required();

const verificationSchema = yup
  .object({
    code: yup
      .string()
      .required("Verification code is required")
      .matches(/^\d{6}$/, "Must be exactly 6 digits"),
  })
  .required();

type PhoneFormType = yup.InferType<typeof phoneSchema>;
type VerificationFormType = yup.InferType<typeof verificationSchema>;

interface TwoFactorAuthDialogProps {
  open: boolean;
  onClose: (verified: boolean) => void;
  verificationMultiFactorAuth: VerificationMultiFactorAuth | null;
}

export const TwoFactorAuthDialog = ({ open, onClose, verificationMultiFactorAuth }: TwoFactorAuthDialogProps) => {
  const userService = useUserService();

  const [activeStep, setActiveStep] = useState<StepIndex>(0);
  const [verificationId, setVerificationId] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [reauthenticateDialogOpen, setReauthenticateDialogOpen] = useState<boolean>(false);

  const {
    handleSubmit: handlePhoneSubmit,
    formState: { errors: phoneErrors },
    register: registerPhone,
    reset: resetPhone,
    setError: setPhoneError,
    getValues: getPhoneValues,
    watch,
  } = useForm<PhoneFormType>({
    resolver: yupResolver(phoneSchema),
    defaultValues: { countryCode: "+61", phoneNumber: "" },
  });

  const countryCode = watch("countryCode");
  const phoneNumber = watch("phoneNumber");

  const formattedPhoneNumber = countryCode && phoneNumber ? `${countryCode} ${phoneNumber}` : "your phone number";

  const {
    handleSubmit: handleCodeSubmit,
    formState: { errors: codeErrors },
    register: registerCode,
    reset: resetCode,
    setError: setCodeError,
  } = useForm<VerificationFormType>({
    resolver: yupResolver(verificationSchema),
    defaultValues: { code: "" },
  });

  const changeStep = (step: StepIndex) => {
    setActiveStep(step);
  };

  const onSubmitPhone = async (data: PhoneFormType) => {
    try {
      setIsSubmitting(true);
      const formattedNumber = `${data.countryCode}${data.phoneNumber}`;
      const id = await userService.verifyPhoneNumber(formattedNumber);

      if (id) {
        if (reauthenticateDialogOpen) {
          setReauthenticateDialogOpen(false);
        }
        setVerificationId(id);
        changeStep(1);
      }
    } catch (err) {
      if (err instanceof FirebaseError && err.code === "auth/requires-recent-login") {
        setReauthenticateDialogOpen(true);
      } else {
        console.error(err);
        setPhoneError("phoneNumber", {
          type: "manual",
          message: "Failed to send verification code. Please try again.",
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmitCode = async (data: VerificationFormType) => {
    if (!verificationId) return;

    try {
      setIsSubmitting(true);

      const isVerified = verificationMultiFactorAuth
        ? await userService.verifySmsTwoFactorSignIn(verificationMultiFactorAuth, data.code)
        : await userService.enrollUserToSmsTwoFactorAuth(verificationId, data.code);

      if (isVerified) {
        handleClose(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      console.error(err);
      setCodeError("code", {
        type: "manual",
        message: "Verification failed. Please try again.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReauthenticate = async (password: string) => {
    await userService.reauthenticate(password);
    await onSubmitPhone(getPhoneValues());
  };

  const handleReauthenticateDialogClose = async (reauthenticated: boolean) => {
    setReauthenticateDialogOpen(false);
    if (reauthenticated) {
      await onSubmitPhone(getPhoneValues());
    }
  };

  const handleClose = (verified: boolean) => {
    changeStep(0);
    setVerificationId(null);
    resetPhone();
    resetCode();
    onClose(verified);
  };

  useEffect(() => {
    if (verificationMultiFactorAuth) {
      setVerificationId(verificationMultiFactorAuth.verificationCodeId);
      changeStep(1);
    }
  }, [verificationMultiFactorAuth]);

  return (
    <>
      <Dialog open={open} onClose={() => handleClose(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Two-factor authentication</DialogTitle>

        <DialogContent sx={{ marginTop: "1.25rem" }}>
          <Grid container spacing={4}>
            {!verificationMultiFactorAuth && (
              <Grid item xs={12}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {STEPS.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            )}

            {activeStep === 0 ? (
              <Grid item xs={12}>
                <form id="phone-form" onSubmit={handlePhoneSubmit(onSubmitPhone)}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        {...registerPhone("phoneNumber")}
                        autoFocus
                        label="Phone Number"
                        placeholder="Enter your phone number"
                        error={!!phoneErrors.phoneNumber}
                        helperText={phoneErrors.phoneNumber?.message}
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          maxLength: 10,
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">+61</InputAdornment>,
                        }}
                      />
                    </FormControl>
                  </Grid>
                </form>
              </Grid>
            ) : (
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    Enter the 6-digit verification code sent to {formattedPhoneNumber}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <form id="verification-form" onSubmit={handleCodeSubmit(onSubmitCode)}>
                    <FormControl fullWidth>
                      <TextField
                        {...registerCode("code")}
                        label="Verification Code"
                        autoFocus
                        inputProps={{
                          maxLength: 6,
                          pattern: "[0-9]*",
                          inputMode: "numeric",
                          style: { textAlign: "center", letterSpacing: "0.5em" },
                        }}
                        placeholder="123456"
                        error={!!codeErrors.code}
                        helperText={codeErrors.code?.message}
                      />
                    </FormControl>
                  </form>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleClose(false)}>Cancel</Button>
          {activeStep === 0 ? (
            <Button type="submit" form="phone-form" variant="contained" disabled={isSubmitting}>
              {isSubmitting ? <CircularProgress size={24} /> : "Send Code"}
            </Button>
          ) : (
            <>
              <Button type="submit" form="verification-form" variant="contained" disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress size={24} /> : "Verify"}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <ReAuthenticationDialog
        fromEnableTwoFactor
        open={reauthenticateDialogOpen}
        onClose={handleReauthenticateDialogClose}
        onConfirm={handleReauthenticate}
      />
    </>
  );
};

export default TwoFactorAuthDialog;
