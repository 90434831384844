import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import BaseAuthPage from "./BaseAuthPage";
import ResetPasswordForm from "./ResetPasswordForm";
import RevertSecondFactorForm from "./RevertSecondFactorForm";
import SignUpForm from "./SignUpForm";
import VerifyEmailPage from "./VerifyEmailPage";

const AuthActionHandlerPage = () => {
  const [inviteCode, setInviteCode] = useState<string>("");
  const [oobCode, setOobCode] = useState<string>("");
  const [mode, setMode] = useState<string>("");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const oobCodeParam = searchParams.get("oobCode");
    if (oobCodeParam) {
      setOobCode(oobCodeParam);
    }

    const modeParam = searchParams.get("mode");
    if (modeParam) {
      setMode(modeParam);
    }

    const inviteCodeParam = searchParams.get("inviteCode");
    if (inviteCodeParam) {
      setInviteCode(inviteCodeParam);
    }
  }, [searchParams]);

  return (
    <BaseAuthPage
      content={
        <>
          {mode === "resetPassword" && <ResetPasswordForm oobCode={oobCode} />}
          {mode === "verifyEmail" && <VerifyEmailPage oobCode={oobCode} />}
          {mode === "revertSecondFactorAddition" && <RevertSecondFactorForm oobCode={oobCode} />}
          {mode === "signUp" && <SignUpForm inviteCode={inviteCode} />}
        </>
      }
      footer={
        <>
          Already have an account? Click <Link to="/login">here</Link> to login.
        </>
      }
    />
  );
};

export default AuthActionHandlerPage;
