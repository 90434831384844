import { DialogContentText } from "@mui/material";

import { FirebaseError } from "firebase/app";
import { useState } from "react";
import useUserService from "../../hooks/UserService";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";
import ReAuthenticationDialog from "./ReAuthenticateDialog";

interface DisableTwoFactorAuthDialogProps {
  open: boolean;
  onClose: (disabled: boolean) => void;
}

export const DisableTwoFactorAuthDialog = ({ open, onClose }: DisableTwoFactorAuthDialogProps) => {
  const userService = useUserService();

  const [reauthenticateDialogOpen, setReauthenticateDialogOpen] = useState<boolean>(false);

  const handleConfirm = async () => {
    try {
      const disabled = await userService.disableUserFromSmsTwoFactorAuth();
      onClose(disabled);
    } catch (e) {
      if (e instanceof FirebaseError && e.code === "auth/requires-recent-login") {
        setReauthenticateDialogOpen(true);
      } else {
        console.error(e);
      }
    }
  };

  const handleReauthenticate = async (password: string) => {
    await userService.reauthenticate(password);
    await handleConfirm();
  };

  const handleReauthenticateDialogClose = async (reauthenticated: boolean) => {
    setReauthenticateDialogOpen(false);
    if (reauthenticated) {
      await handleConfirm();
    }
  };

  return (
    <>
      <ConfirmationDialog
        title="Disable two-factor authentication"
        confirmButtonLabel="Yes"
        cancelButtonLabel="No"
        maxWidth="sm"
        open={open}
        onClose={() => onClose(false)}
        onConfirm={handleConfirm}
        content={
          <DialogContentText>
            Are you sure you want to disable two-factor authentication? This will make your account less secure.
          </DialogContentText>
        }
      />

      <ReAuthenticationDialog
        open={reauthenticateDialogOpen}
        onClose={handleReauthenticateDialogClose}
        onConfirm={handleReauthenticate}
      />
    </>
  );
};
