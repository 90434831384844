import {
  Add as AddIcon,
  Checklist as ChecklistIcon,
  Close as CloseIcon,
  EventRepeat as EventRepeatIcon,
} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button, Checkbox, LinearProgress, TableSortLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { get, includes, keyBy, orderBy } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RequirementStatusEnumAgentDisplay, RequirementStatusEnumClientDisplay } from "../../enums/RequirementStatus";
import { useApplicationState } from "../../hooks/ApplicationState";
import useClientService from "../../hooks/ClientService";
import useSessionStorageService from "../../hooks/SessionStorageService";
import useUserService from "../../hooks/UserService";
import { ClientType, UserType } from "../../types";
import ClientAvatar from "../client/ClientAvatar";
import ClientStatusChip from "../client/ClientStatusChip";
import RolloverClientsDialog from "../client/dialogs/RolloverClientsDialog";
import FormattedTimestamp from "../FormattedTimestamp";
import FormattedUserName from "../FormattedUserName";
import AddClientDialog from "./AddClientDialog";
import ClientSearch from "./ClientSearch";
import DeleteClientDialog from "./DeleteClientDialog";
import SendMessageDialog from "./SendMessageDialog";

type SortBy = "name" | "manager" | "nextDueDate" | "activePeriodName" | "status" | "progress";
type SortDirection = "asc" | "desc";

const Home = () => {
  const clientService = useClientService();
  const userService = useUserService();
  const sessionStorageService = useSessionStorageService();
  const navigate = useNavigate();
  const { isAccountant, organisationIds } = useApplicationState();

  const [sortBy, setSortBy] = useState<SortBy>();
  const [sortDirection, setSortDirection] = useState<SortDirection>("asc");

  const [clients, setClients] = useState<ClientType[]>([]);

  // clients after applying search filters
  const [filteredClients, setFilteredClients] = useState<ClientType[]>([]);

  // clients after applying sort filters
  const [sortedClients, setSortedClients] = useState<ClientType[]>([]);

  const [accountants, setAccountants] = useState<Record<string, UserType>>({});

  const [addClientDialogOpen, setAddClientDialogOpen] = useState<boolean>(false);
  const [clientToDelete, setClientToDelete] = useState<ClientType>();
  const [clientToMessage, setClientToMessage] = useState<ClientType>();

  const [menuClient, setMenuClient] = useState<ClientType>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const [isEnableRollover, setIsEnableRollover] = useState<boolean>(false);
  const [rolloverClientsDialogOpen, setRolloverClientsDialogOpen] = useState<boolean>(false);
  const [selectedClients, setSelectedClients] = useState<Set<string>>(new Set());
  const [rolloverClients, setRolloverClients] = useState<ClientType[]>([]);

  const loadClients = useCallback(async () => {
    const results = await clientService.getClients(organisationIds);
    setClients(results);
  }, [organisationIds, clientService]);

  const loadAccountants = useCallback(async () => {
    if (isAccountant) {
      const results = await userService.listAccountantUsers(organisationIds);
      setAccountants(keyBy(results, (result) => result.uid));
    }
  }, [organisationIds, userService, isAccountant]);

  useEffect(() => {
    void loadClients();
    void loadAccountants();
  }, [loadClients, loadAccountants]);

  useEffect(() => {
    if (sortBy && includes(["name", "nextDueDate", "activePeriodName", "status"], sortBy)) {
      setSortedClients(orderBy(filteredClients, (client) => client[sortBy as keyof ClientType], sortDirection));
    } else if (sortBy === "progress") {
      setSortedClients(
        orderBy(filteredClients, (client) => get(client.requirementCounts, "PERCENTAGE_COMPLETED", 0), sortDirection)
      );
    } else if (sortBy === "manager") {
      setSortedClients(
        orderBy(
          filteredClients,
          (client) => {
            const manager = client.manager ? accountants[client.manager] : undefined;
            return manager ? `${manager.firstName} ${manager.lastName}` : "";
          },
          sortDirection
        )
      );
    } else {
      setSortedClients(filteredClients);
    }
  }, [sortBy, sortDirection, filteredClients, accountants]);

  useEffect(() => {
    if (sortBy) {
      sessionStorageService.setClientSortBy(sortBy);
      sessionStorageService.setClientSortDirection(sortDirection);
    }
  }, [sessionStorageService, sortBy, sortDirection]);

  useEffect(() => {
    const sortBy = sessionStorageService.getClientSortBy();
    const sortDirection = sessionStorageService.getClientSortDirection();
    if (sortBy) setSortBy(sortBy as SortBy);
    if (sortDirection) setSortDirection(sortDirection as SortDirection);
  }, [sessionStorageService]);

  const sortTable = (fieldName: SortBy) => {
    setSortBy(fieldName);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const handleSearchResults = (results: ClientType[]) => {
    setFilteredClients(results);
  };

  const handleKebabMenuClick = (event: React.MouseEvent<HTMLElement>, client: ClientType) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setMenuClient(client);
  };

  const handleSelectClient = (event: React.ChangeEvent<HTMLInputElement>, clientId: string) => {
    event.stopPropagation();

    const newSelected = new Set(selectedClients);

    if (newSelected.has(clientId)) {
      newSelected.delete(clientId);
    } else {
      newSelected.add(clientId);
    }

    setSelectedClients(newSelected);
  };

  const rolloverClientsOpen = () => {
    const rollOverClients = filteredClients
      .filter((client) => selectedClients.has(client.clientId))
      .sort((a, b) => a.name.localeCompare(b.name));

    setRolloverClients(rollOverClients);
    setRolloverClientsDialogOpen(true);
  };

  const rolloverClientsClose = async (isConfirmed: boolean) => {
    if (isConfirmed) {
      cancelRollover();
      await loadClients();
    }

    setRolloverClientsDialogOpen(false);
  };

  const enableRollover = () => {
    setIsEnableRollover(true);
  };

  const cancelRollover = () => {
    setIsEnableRollover(false);
    setSelectedClients(new Set());
  };

  const addClient = () => {
    setAddClientDialogOpen(true);
  };

  const addClientClose = () => {
    setAddClientDialogOpen(false);
  };

  const viewClient = async (client: ClientType) => {
    if (isAccountant && client.isUpdated) {
      await clientService.clearIsUpdated(client);
    }
    navigate(clientService.getClientUrl(client.organisationId, client.clientId));
  };

  const manageClient = () => {
    if (menuClient) {
      setAnchorEl(undefined);
      navigate(`${clientService.getClientUrl(menuClient.organisationId, menuClient.clientId)}/manage`);
    }
  };

  const deleteClient = () => {
    if (menuClient) {
      setAnchorEl(undefined);
      setClientToDelete(menuClient);
    }
  };

  const deleteClientClose = (clientDeleted: boolean) => {
    setClientToDelete(undefined);
    if (clientDeleted) {
      void loadClients();
    }
  };

  const sendMessageToClient = () => {
    if (menuClient) {
      setAnchorEl(undefined);
      setClientToMessage(menuClient);
    }
  };

  const sendMessageToClientClose = () => {
    setClientToMessage(undefined);
  };

  const SortLabel = ({ field, label }: { field: SortBy; label: string }) => {
    return (
      <TableSortLabel active={sortBy === field} direction={sortDirection} onClick={() => sortTable(field)}>
        {label}
      </TableSortLabel>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid",
          borderColor: "neutral.light",
          p: 2,
        }}
      >
        <Typography variant="h4">Clients</Typography>
        {isAccountant && (
          <Box display="flex" gap={1}>
            <Button variant="contained" startIcon={<AddIcon />} size="small" onClick={addClient}>
              New client
            </Button>

            {isEnableRollover ? (
              <>
                {selectedClients.size > 0 && (
                  <Button
                    variant="outlined"
                    startIcon={<EventRepeatIcon />}
                    size="small"
                    onClick={rolloverClientsOpen}
                    disabled={selectedClients.size === 0}
                  >
                    Rollover
                  </Button>
                )}

                <Button
                  variant="outlined"
                  color="secondary"
                  endIcon={<CloseIcon fontSize="inherit" />}
                  size="small"
                  onClick={cancelRollover}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button variant="outlined" startIcon={<ChecklistIcon />} size="small" onClick={enableRollover}>
                Select clients
              </Button>
            )}
          </Box>
        )}
      </Box>

      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sx={{ my: 2 }}>
          <ClientSearch clients={clients} onSearchResults={handleSearchResults} />
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper} square elevation={0}>
            <Table aria-label="Clients" size="medium">
              <TableHead>
                <TableRow>
                  {isEnableRollover && <TableCell></TableCell>}

                  <TableCell>
                    <SortLabel field="name" label="Client" />
                  </TableCell>

                  {isAccountant && (
                    <TableCell>
                      <SortLabel field="manager" label="Manager" />
                    </TableCell>
                  )}

                  <TableCell>
                    <SortLabel field="nextDueDate" label="Next due date" />
                  </TableCell>

                  <TableCell>
                    <SortLabel field="activePeriodName" label="Active period" />
                  </TableCell>

                  <TableCell sx={{ px: 0.5, textAlign: "right" }}>
                    <SortLabel field="progress" label="Progress" />
                  </TableCell>

                  <TableCell sx={{ px: 0.5, textAlign: "right" }}>
                    {isAccountant
                      ? RequirementStatusEnumAgentDisplay["WITH_CLIENT"]
                      : RequirementStatusEnumClientDisplay["WITH_CLIENT"]}
                  </TableCell>
                  <TableCell sx={{ px: 0.5, textAlign: "right" }}>
                    {isAccountant
                      ? RequirementStatusEnumAgentDisplay["WITH_ACCOUNTANT"]
                      : RequirementStatusEnumClientDisplay["WITH_ACCOUNTANT"]}
                  </TableCell>
                  <TableCell sx={{ px: 0.5, textAlign: "right" }}>
                    {isAccountant
                      ? RequirementStatusEnumAgentDisplay["COMPLETE"]
                      : RequirementStatusEnumClientDisplay["COMPLETE"]}
                  </TableCell>

                  <TableCell sx={{ textAlign: "right" }}>
                    <SortLabel field="status" label="Status" />
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedClients.map((client) => (
                  <TableRow key={client.clientId} onClick={() => viewClient(client)} hover sx={{ cursor: "pointer" }}>
                    {/* SELECT CLIENT CHECKBOX */}
                    {isEnableRollover && (
                      <TableCell padding="checkbox" onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                          color="primary"
                          checked={selectedClients.has(client.clientId)}
                          onChange={(event) => handleSelectClient(event, client.clientId)}
                        />
                      </TableCell>
                    )}

                    {/* AVATAR AND NAME */}
                    <TableCell sx={{ py: 1.5 }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ClientAvatar client={client} />
                        <Typography component="div" sx={{ fontWeight: "bold" }}>
                          {client.name}
                        </Typography>
                      </Box>
                    </TableCell>

                    {/* MANAGER */}
                    {isAccountant && (
                      <TableCell sx={{ py: 1.5 }}>
                        <Typography>
                          <FormattedUserName
                            user={client.manager ? accountants[client.manager] : undefined}
                            defaultValue="Not assigned"
                          />
                        </Typography>
                      </TableCell>
                    )}

                    {/* NEXT DUE DATE */}
                    <TableCell sx={{ py: 1.5 }}>
                      <Typography>
                        <FormattedTimestamp timestamp={client.nextDueDate} defaultValue="Not set" />
                      </Typography>
                    </TableCell>

                    {/* ACTIVE PERIOD */}
                    <TableCell sx={{ py: 1.5 }}>
                      <Typography>{client.activePeriodName || ""}</Typography>
                    </TableCell>

                    {/* PROGRESS */}
                    <TableCell sx={{ py: 1.5 }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <LinearProgress
                          variant="determinate"
                          value={get(client.requirementCounts, "PERCENTAGE_COMPLETED", 0)}
                          sx={{ width: "100%", mr: 1 }}
                        />
                        <Typography variant="body2" color="text.secondary">
                          {`${get(client.requirementCounts, "PERCENTAGE_COMPLETED", "0")}%`}
                        </Typography>
                      </Box>
                    </TableCell>

                    {/* REQUIREMENT COUNTS */}
                    <TableCell sx={{ textAlign: "right" }}>
                      {get(client.requirementCounts, "WITH_CLIENT", "")}
                    </TableCell>
                    <TableCell sx={{ textAlign: "right" }}>
                      {get(client.requirementCounts, "WITH_ACCOUNTANT", "")}
                    </TableCell>
                    <TableCell sx={{ textAlign: "right" }}>{get(client.requirementCounts, "COMPLETE", "")}</TableCell>

                    {/* STATUS AND OPTIONS */}
                    <TableCell sx={{ py: 1.5 }}>
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <Box>
                          <ClientStatusChip client={client} />
                        </Box>
                        <Box>
                          {isAccountant && (
                            <IconButton
                              size="small"
                              aria-label="More options"
                              sx={{ ml: 1 }}
                              onClick={(event) => handleKebabMenuClick(event, client)}
                            >
                              <MoreVertIcon fontSize="inherit" />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* KEBAB MENU ACTIONS */}
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(undefined)}>
        <MenuItem onClick={deleteClient} dense>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete client</ListItemText>
        </MenuItem>
        <MenuItem onClick={manageClient} dense>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Manage client</ListItemText>
        </MenuItem>
        <MenuItem onClick={sendMessageToClient} dense>
          <ListItemIcon>
            <EmailIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Send message</ListItemText>
        </MenuItem>
      </Menu>

      <AddClientDialog open={addClientDialogOpen} onClose={addClientClose} />

      {!!clientToDelete && <DeleteClientDialog client={clientToDelete} hasPeriods={true} onClose={deleteClientClose} />}

      {!!clientToMessage && <SendMessageDialog client={clientToMessage} onClose={sendMessageToClientClose} />}

      <RolloverClientsDialog
        open={rolloverClientsDialogOpen}
        onClose={rolloverClientsClose}
        clients={rolloverClients}
      />
    </>
  );
};

export default Home;
