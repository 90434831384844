import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getFirestore,
  query,
  QueryConstraint,
  serverTimestamp,
  UpdateData,
  where,
  writeBatch,
} from "firebase/firestore";
import { chain, orderBy } from "lodash";
import { useMemo } from "react";
import { ClientType, FileMetadataType, FileType, PeriodType, ShoeboxItem, UserType } from "../types";
import getFileExtension from "../utilities/getFileExtension";
import { useApplicationState } from "./ApplicationState";
import useFirestoreService, { FirestoreCreate, FirestoreService, FirestoreUpdate } from "./FirestoreService";
import useHttpFunctions, { HttpFunctions } from "./HttpFunctions";
import ServiceError from "./ServiceError";

export class ClientService {
  constructor(
    readonly db: Firestore,
    readonly firestoreService: FirestoreService,
    readonly isAccountant: boolean,
    readonly isAdmin: boolean,
    readonly user: UserType | undefined,
    readonly httpFunctions: HttpFunctions
  ) {}

  public getClientUrl(organisationId: string, clientId: string) {
    return `/client/${organisationId}/${clientId}`;
  }

  public async getClient(organisationId: string, clientId: string): Promise<ClientType | undefined> {
    const documentReference = this.firestoreService.getClientDocument(organisationId, clientId);
    const documentSnapshot = await this.firestoreService.getDocumentSnapshot(documentReference);
    return documentSnapshot.exists() ? (documentSnapshot.data() as ClientType) : undefined;
  }

  public watchClient(organisationId: string, clientId: string, callback: (client: ClientType) => void) {
    const documentReference = this.firestoreService.getClientDocument(organisationId, clientId);
    return this.firestoreService.watchDocument(documentReference, (snapshot) => {
      callback(this.firestoreService.toDocument<ClientType>(snapshot));
    });
  }

  public async getShoeboxItems(organisationId: string, clientId: string): Promise<ShoeboxItem[]> {
    const shoeboxItemsCollection = this.firestoreService.getShoeboxItemsCollection(organisationId, clientId);
    const shoeboxItemsQuery = query(shoeboxItemsCollection, where("status", "==", "PENDING"));
    const querySnapshot = await this.firestoreService.getDocs(shoeboxItemsQuery);
    return querySnapshot.docs.map((snapshot) => this.firestoreService.toDocument<ShoeboxItem>(snapshot));
  }

  public async archiveShoeboxItem(organisationId: string, clientId: string, shoeboxId: string): Promise<void> {
    const documentReference = this.firestoreService.getShoeboxItemDocument(organisationId, clientId, shoeboxId);
    const documentSnapshot = await this.firestoreService.getDocumentSnapshot(documentReference);
    if (documentSnapshot.exists()) {
      const update: FirestoreUpdate<ShoeboxItem> = {
        status: "ARCHIVED",
      };
      await this.firestoreService.updateDocument(documentReference, update);
    }
  }

  public async getPeriods(organisationId: string, clientId: string): Promise<PeriodType[]> {
    const periodsCollection = this.firestoreService.getPeriodsCollection(organisationId, clientId);
    const querySnapshot = await this.firestoreService.getDocs(periodsCollection);
    const periods = querySnapshot.docs.map((snapshot) => this.firestoreService.toDocument<PeriodType>(snapshot));
    return orderBy(periods, (period) => period.name, "desc");
  }

  public async getPeriod(organisationId: string, clientId: string, periodId: string): Promise<PeriodType | undefined> {
    const periodDocument = this.firestoreService.getPeriodDocument(organisationId, clientId, periodId);
    const periodSnapshot = await this.firestoreService.getDocumentSnapshot(periodDocument);
    if (periodSnapshot.exists()) {
      return this.firestoreService.toDocument<PeriodType>(periodSnapshot);
    }
  }

  public watchFiles(organisationId: string, clientId: string, periodId: string, callback: () => void): () => void {
    const collectionReference = this.firestoreService.getFilesCollection(organisationId, clientId, periodId);
    return this.firestoreService.watchCollection(collectionReference, callback);
  }

  public async getFiles(organisationId: string, clientId: string, periodId: string) {
    const collectionReference = this.firestoreService.getFilesCollection(organisationId, clientId, periodId);
    const querySnapshot = await this.firestoreService.getDocs(collectionReference);
    const files = querySnapshot.docs.map((snapshot) => snapshot.data() as FileType);

    return orderBy(files, (file) => file.dateUploaded, "desc");
  }

  public async createFile(
    organisationId: string,
    clientId: string,
    periodId: string,
    storageLocation: string,
    metadata: FileMetadataType
  ): Promise<FileType> {
    const filesCollection = this.firestoreService.getFilesCollection(organisationId, clientId, periodId);
    const fileDocument = doc(filesCollection);
    const data: FirestoreCreate<FileType> = {
      fileId: fileDocument.id,
      name: metadata.customMetadata.name,
      storageLocation,
      downloadURL: `/download/${organisationId}/${clientId}/${periodId}/${fileDocument.id}`,
      dateUploaded: serverTimestamp(),
      isPermanent: false,
      metadata,
    };

    await this.firestoreService.createDocument(fileDocument, data);

    const documentSnapshot = await this.firestoreService.getDocumentSnapshot(fileDocument);
    return documentSnapshot.data() as FileType;
  }

  public async renameFile(organisationId: string, clientId: string, periodId: string, fileId: string, name: string) {
    const fileDocumentRef = this.firestoreService.getFileDocument(organisationId, clientId, periodId, fileId);
    const fileDocument = await this.firestoreService.getDocumentSnapshot(fileDocumentRef);
    if (fileDocument.exists()) {
      const data = this.firestoreService.toDocument<FileType>(fileDocument);
      const existingFileName = data.name;
      // FIXME bug if the file doesn't have an extension - https://trello.com/c/yrVJqmxd
      const extension = getFileExtension(existingFileName);
      const newFileName = `${name}.${extension}`;
      const update: FirestoreUpdate<FileType> = {
        name: newFileName,
      };
      await this.firestoreService.updateDocument(fileDocumentRef, update);
    }
  }

  public async togglePermanent(organisationId: string, clientId: string, periodId: string, fileId: string) {
    const fileDocumentRef = this.firestoreService.getFileDocument(organisationId, clientId, periodId, fileId);
    const fileDocument = await this.firestoreService.getDocumentSnapshot(fileDocumentRef);
    if (fileDocument.exists()) {
      const data = this.firestoreService.toDocument<FileType>(fileDocument);
      const { isPermanent = false } = data;
      const update: FirestoreUpdate<FileType> = {
        isPermanent: !isPermanent,
      };
      await this.firestoreService.updateDocument(fileDocumentRef, update);
    }
  }

  public async createPeriod(
    organisationId: string,
    clientId: string,
    name: string,
    makeActive: boolean
  ): Promise<PeriodType> {
    try {
      const periodsCollection = this.firestoreService.getPeriodsCollection(organisationId, clientId);

      // check the period doesn't already exist
      const periodsQuery = query(periodsCollection, where("name", "==", name));
      const count = await this.firestoreService.countDocs(periodsQuery);
      if (count > 0) {
        throw new Error(`Period ${name} already exists`);
      }

      const newPeriodDocument = await this.firestoreService.inTransaction(async (transaction) => {
        const periodDocument = doc(periodsCollection);
        const periodData: FirestoreCreate<PeriodType> = {
          periodId: periodDocument.id,
          name,
          createdTimestamp: serverTimestamp(),
        };
        transaction.set(periodDocument, periodData);

        if (makeActive) {
          const clientDocument = this.firestoreService.getClientDocument(organisationId, clientId);
          const clientData: UpdateData<ClientType> = {
            activePeriodId: periodDocument.id,
            activePeriodName: name,
          };
          transaction.update(clientDocument, clientData);
        }

        return periodDocument;
      });

      // return the newly created period
      const newPeriodSnapshot = await this.firestoreService.getDocumentSnapshot(newPeriodDocument);
      return this.firestoreService.toDocument<PeriodType>(newPeriodSnapshot);
    } catch (e) {
      if (e instanceof Error) {
        throw new ServiceError(e.message);
      } else {
        console.error(e);
        throw new ServiceError(`Unexpected error attempting to create period ${name} for client ${clientId}`);
      }
    }
  }

  public async setActivePeriod(organisationId: string, clientId: string, activePeriodId: string) {
    const periodDocument = this.firestoreService.getPeriodDocument(organisationId, clientId, activePeriodId);
    const periodSnapshot = await this.firestoreService.getDocumentSnapshot(periodDocument);
    if (!periodSnapshot.exists()) {
      throw new Error(`Period ${activePeriodId} does not exist on client ${clientId}`);
    } else {
      const { name } = periodSnapshot.data();
      const clientDocument = this.firestoreService.getClientDocument(organisationId, clientId);
      await this.firestoreService.updateDocument(clientDocument, {
        activePeriodId,
        activePeriodName: name,
        modifiedTimestamp: serverTimestamp(),
      });
    }
  }

  public async assignManager(organisationId: string, clientId: string, user: UserType) {
    const clientDocument = this.firestoreService.getClientDocument(organisationId, clientId);
    const clientSnapshot = await this.firestoreService.getDocumentSnapshot(clientDocument);

    if (!clientSnapshot.exists()) {
      throw new Error("Error assigning accountant to client. Client does not exist");
    } else {
      const update: UpdateData<ClientType> = {
        manager: user.uid,
        modifiedTimestamp: serverTimestamp(),
      };
      await this.firestoreService.updateDocument(clientDocument, update);
    }
  }

  public async clearIsUpdated(client: ClientType) {
    const clientDocument = this.firestoreService.getClientDocument(client.organisationId, client.clientId);
    const clientSnapshot = await this.firestoreService.getDocumentSnapshot(clientDocument);

    if (!clientSnapshot.exists()) {
      throw new Error("Error updating client. Client does not exist");
    } else {
      const { isUpdated = false } = clientSnapshot.data();
      if (isUpdated) {
        const update: UpdateData<ClientType> = {
          isUpdated: false,
        };
        await this.firestoreService.updateDocument(clientDocument, update);
      }
    }
  }

  public async getClients(organisationIds: string[]): Promise<ClientType[]> {
    try {
      const constraints: QueryConstraint[] = [];
      if (!this.isAccountant && !this.isAdmin) {
        // TODO refactor this to not user state
        const clientIds: string[] = this.user?.clientIds || [];
        constraints.push(where("clientId", "in", clientIds));
      }

      // query for clients across all the given organisations
      const clients = await Promise.all(
        organisationIds.map(async (organisationId) => {
          const collectionReference = this.firestoreService.getClientsCollection(organisationId);
          const clientsQuery = query(collectionReference, ...constraints);
          const querySnapshot = await this.firestoreService.getDocs(clientsQuery);
          return querySnapshot.docs.map((snapshot) => snapshot.data() as ClientType);
        })
      );

      return chain(clients).flattenDeep().value();
    } catch (e) {
      console.error(e);
      throw new ServiceError("Unable to load clients");
    }
  }

  public async createClient(organisationId: string, name: string, periodName: string): Promise<string> {
    try {
      const batch = writeBatch(this.db);

      const clientsCollectionReference = this.firestoreService.getClientsCollection(organisationId);
      const clientDocRef = doc(clientsCollectionReference);
      const periodDocRef = doc(collection(clientDocRef, "periods"));

      const clientId = clientDocRef.id;
      const clientData: FirestoreCreate<ClientType> = {
        clientId,
        organisationId,
        name,
        activePeriodId: periodDocRef.id,
        activePeriodName: periodName,
        createdTimestamp: serverTimestamp(),
        modifiedTimestamp: serverTimestamp(),
      };
      batch.set(clientDocRef, clientData);

      const periodData: FirestoreCreate<PeriodType> = {
        periodId: periodDocRef.id,
        name: periodName,
        createdTimestamp: serverTimestamp(),
      };
      batch.set(periodDocRef, periodData);

      await batch.commit();
      return clientId;
    } catch (e) {
      if (e instanceof Error) {
        throw new ServiceError(e.message);
      } else {
        console.error(e);
        throw new ServiceError(`Unexpected error attempting to create client ${name}`);
      }
    }
  }

  public async deleteClient(organisationId: string, clientId: string) {
    try {
      const organisationRef = doc(collection(this.db, "organisations"), organisationId);
      const clientDocRef = doc(organisationRef, "clients", clientId);
      await deleteDoc(clientDocRef);
    } catch (e) {
      throw new ServiceError(`Error deleting client ${clientId}`);
    }
  }

  public async addUserToClient(
    organisationId: string,
    clientId: string,
    firstName: string,
    lastName: string,
    email: string
  ): Promise<string | undefined> {
    const { data } = await this.httpFunctions.addUser({
      role: "CLIENT",
      firstName,
      lastName,
      email,
      organisationId,
      clientId,
    });

    if (!data.success) {
      throw new ServiceError(data.message);
    } else {
      return data.message;
    }
  }

  public async removeUserFromClient(
    organisationId: string,
    clientId: string,
    userId: string
  ): Promise<string | undefined> {
    const { data } = await this.httpFunctions.removeUser({
      userType: "CLIENT",
      organisationId,
      clientId,
      userId,
    });

    if (!data.success) {
      throw new ServiceError(data.message);
    } else {
      return data.message;
    }
  }
}

const useClientService = () => {
  const db = getFirestore();
  const firestoreService = useFirestoreService();
  const httpFunctions = useHttpFunctions();
  const { isAccountant, isAdmin, user } = useApplicationState();
  return useMemo(
    () => new ClientService(db, firestoreService, isAccountant, isAdmin, user, httpFunctions),
    [db, firestoreService, isAccountant, isAdmin, user, httpFunctions]
  );
};

export default useClientService;
