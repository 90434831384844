import React from "react";
import useOrganisationService from "../../hooks/OrganisationService";
import { UserType } from "../../types";
import Typography from "@mui/material/Typography";
import { Box, DialogContentText } from "@mui/material";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";

type RemoveTeamMemberDialogProps = {
  organisationId: string;
  user: UserType;
  open: boolean;
  onClose: (userRemoved: boolean) => void;
};

const RemoveTeamMemberDialog = ({ organisationId, user, open, onClose }: RemoveTeamMemberDialogProps) => {
  const organisationService = useOrganisationService();

  const handleRemoveUser = async () => {
    await organisationService.removeUserFromOrganisation(organisationId, user.uid);
    onClose(true);
  };

  return (
    <ConfirmationDialog
      open={open}
      title="Remove team member"
      content={
        <>
          <DialogContentText>Are you sure you want to remove the following user from the team?</DialogContentText>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1">
              {user.firstName} {user.lastName}
            </Typography>
            <Typography variant="body1">{user.email}</Typography>
          </Box>
        </>
      }
      confirmButtonLabel="Remove"
      cancelButtonLabel="Cancel"
      onClose={() => onClose(false)}
      onConfirm={handleRemoveUser}
      maxWidth="sm"
    />
  );
};

export default RemoveTeamMemberDialog;
