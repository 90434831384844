import LogoutIcon from "@mui/icons-material/Logout";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonIcon from "@mui/icons-material/Person";
import SecurityIcon from "@mui/icons-material/Security";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { FormControlLabel, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { join } from "lodash";
import { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DisableTwoFactorAuthDialog } from "./components/auth/DisableTwoFactorAuthDialog";
import TwoFactorAuthDialog from "./components/auth/TwoFactorAuthDialog";
import { useApplicationState } from "./hooks/ApplicationState";
import TaxyTooltip from "./components/TaxyTooltip";
import RequestVerifyEmailDialog from "./components/auth/RequestVerifyEmailDialog";

type InformationItemProps = {
  icon: ReactElement;
  tooltip: string;
  value: string;
  action?: ReactElement;
};

const InformationItem = ({ icon, value, action, tooltip }: InformationItemProps) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <TaxyTooltip title={tooltip}>
        <Box sx={{ color: "text.secondary" }}>{icon}</Box>
      </TaxyTooltip>

      <Box>
        <Typography variant="body1" sx={{ ml: 2, flex: 1 }}>
          {value}
        </Typography>
      </Box>
      {action && <Box sx={{ ml: 2 }}>{action}</Box>}
    </Box>
  );
};

const User = () => {
  const navigate = useNavigate();
  const { user, signInSecondFactor, emailVerified } = useApplicationState();

  const [twoFactorDialogOpen, setTwoFactorDialogOpen] = useState(false);
  const [disableTwoFactorDialogOpen, setDisableTwoFactorDialogOpen] = useState(false);
  const [requestVerifyEmailDialogOpen, setRequestVerifyEmailDialogOpen] = useState<boolean>(false);

  const logout = () => {
    navigate("/logout");
  };

  const onTwoFactorDialogClose = (enableTwoFactor: boolean) => {
    setTwoFactorDialogOpen(false);
    if (enableTwoFactor) {
      window.location.reload();
    }
  };

  const onDisableTwoFactorDialogClose = (disableTwoFactor: boolean) => {
    setDisableTwoFactorDialogOpen(false);
    if (disableTwoFactor) {
      window.location.reload();
    }
  };

  const onRequestVerifyEmailDialogClose = (requested: boolean) => {
    setRequestVerifyEmailDialogOpen(false);
    if (requested) {
      navigate("/logout");
    }
  };

  const onSwitchClick = (checked: boolean) => {
    if (checked) {
      if (emailVerified) {
        setTwoFactorDialogOpen(true);
      } else {
        setRequestVerifyEmailDialogOpen(true);
      }
      setDisableTwoFactorDialogOpen(false);
    } else {
      setDisableTwoFactorDialogOpen(true);
      setTwoFactorDialogOpen(false);
    }
  };

  return (
    <Box>
      <Box sx={{ borderBottom: "1px solid", borderColor: "neutral.light", p: 2 }}>
        <Typography variant="h4">User</Typography>
      </Box>

      <Grid container spacing={2} p={2}>
        {user && (
          <>
            <Grid item xs={12}>
              <InformationItem icon={<PersonIcon />} value={`${user.firstName} ${user.lastName}`} tooltip="Name" />
            </Grid>

            <Grid item xs={12}>
              <InformationItem icon={<MailOutlineIcon />} value={user.email} tooltip="Email" />
            </Grid>

            <Grid item xs={12}>
              <InformationItem icon={<VerifiedUserIcon />} value={join(user.roles, ", ")} tooltip="Roles" />
            </Grid>

            <Grid item xs={12}>
              <InformationItem
                icon={<SecurityIcon />}
                value="Two-factor authentication"
                tooltip="2FA"
                action={
                  <FormControlLabel
                    label={signInSecondFactor === "phone" ? "Enabled" : "Disabled"}
                    control={
                      <Switch
                        color="primary"
                        checked={signInSecondFactor === "phone"}
                        onChange={(e) => onSwitchClick(e.target.checked)}
                      />
                    }
                  ></FormControlLabel>
                }
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button variant="contained" startIcon={<LogoutIcon />} onClick={logout} size="small">
            Logout
          </Button>
        </Grid>
      </Grid>

      <TwoFactorAuthDialog
        open={twoFactorDialogOpen}
        onClose={onTwoFactorDialogClose}
        verificationMultiFactorAuth={null}
      />

      <RequestVerifyEmailDialog open={requestVerifyEmailDialogOpen} onClose={onRequestVerifyEmailDialogClose} />

      <DisableTwoFactorAuthDialog open={disableTwoFactorDialogOpen} onClose={onDisableTwoFactorDialogClose} />
    </Box>
  );
};

export default User;
