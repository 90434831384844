import { Breakpoint } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { ReactElement } from "react";
import { useForm } from "react-hook-form";
import SubmitButton from "../button/SubmitButton";
import Typography from "@mui/material/Typography";

type ConfirmationDialogProps = {
  open: boolean;
  title: string;
  content: ReactElement;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  maxWidth?: Breakpoint;
};

const ConfirmationDialog = ({
  open,
  title,
  content,
  onClose,
  onConfirm,
  confirmButtonLabel,
  cancelButtonLabel,
  maxWidth = "md",
}: ConfirmationDialogProps) => {
  const {
    handleSubmit,
    reset,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async () => {
    try {
      await onConfirm();
    } catch (e: any) {
      const message = e.message || "An unexpected error has occurred";
      setError("root", { type: "custom", message });
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={maxWidth}>
      <DialogTitle>{title}</DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          {content}

          {errors.root && (
            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
              {errors.root.message}
            </Typography>
          )}
        </DialogContent>

        <DialogActions sx={{ m: 1 }}>
          {cancelButtonLabel && (
            <Button onClick={handleClose} variant="outlined" color="secondary">
              {cancelButtonLabel}
            </Button>
          )}

          {confirmButtonLabel && <SubmitButton loading={isSubmitting} label={confirmButtonLabel} />}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConfirmationDialog;
