import dayjs from "dayjs";
import convertServerTimestamp from "../utilities/convertServerTimestamp";
import { InviteType } from "../types";
import TaxyTooltip from "./TaxyTooltip";
import Box from "@mui/material/Box";

const INVITE_EXPIRY_DAYS = 30;

type InviteExpiryProps = {
  invite: InviteType;
};

const InviteExpiry = ({ invite }: InviteExpiryProps) => {
  const created = dayjs(convertServerTimestamp(invite.createdTimestamp));
  const expiryDate = created.add(INVITE_EXPIRY_DAYS, "days");
  return invite.status === "PENDING" ? (
    <Box sx={{ display: "flex", alignItems: "start" }}>
      <TaxyTooltip
        placement="bottom-start"
        title="After this date the user will no longer be able to accept the invite"
      >
        <Box>{expiryDate.format("DD/MM/YYYY")}</Box>
      </TaxyTooltip>
    </Box>
  ) : (
    <>-</>
  );
};

export default InviteExpiry;
