import { useCallback, useEffect } from "react";
import { useApplicationState } from "../../hooks/ApplicationState";
import useLocalStorageService from "../../hooks/LocalStorageService";
import useSessionStorageService from "../../hooks/SessionStorageService";
import useUserService from "../../hooks/UserService";

const LogoutPage = () => {
  const { loggedIn } = useApplicationState();
  const userService = useUserService();
  const sessionStorageService = useSessionStorageService();
  const localStorageService = useLocalStorageService();

  const logout = useCallback(async () => {
    if (loggedIn) {
      await userService.signOut();
    }
    localStorageService.removeRememberMe();
    sessionStorageService.clearStorage();
    window.location.href = "/login";
  }, [localStorageService, loggedIn, sessionStorageService, userService]);

  useEffect(() => {
    void logout();
  }, [logout]);

  return null;
};

export default LogoutPage;
