import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import useAdminService from "../../../hooks/AdminService";
import SubmitButton from "../../button/SubmitButton";

const formSchema = yup
  .object({
    firstName: yup.string().trim().required("First name is required"),
    lastName: yup.string().trim().required("Last name is required"),
    email: yup.string().email("Email must be a valid email address").required("Email is required"),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

type AddAdminUserDialogProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: (message?: string) => void;
};

const AddAdminUserDialog = ({ open, onClose, onSuccess }: AddAdminUserDialogProps) => {
  const adminService = useAdminService();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormType>({ resolver: yupResolver(formSchema) });

  const onSubmit = async (data: FormType) => {
    const { firstName, lastName, email } = data;
    try {
      const message = await adminService.createAdminUser(firstName, lastName, email);
      reset();
      onSuccess(message);
    } catch (e: any) {
      const message = e.message || "An unexpected error has occurred";
      setError("email", { type: "custom", message });
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} disableRestoreFocus fullWidth maxWidth="sm">
      <DialogTitle>Add or invite admin user</DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                autoFocus
                fullWidth
                label="First name"
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                {...register("firstName")}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Last name"
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                {...register("lastName")}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Email"
                error={!!errors.email}
                helperText={errors.email?.message}
                {...register("email")}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ m: 2 }}>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <SubmitButton loading={isSubmitting} label="Save" />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddAdminUserDialog;
