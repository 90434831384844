import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUserService from "../../hooks/UserService";

type VerifyEmailFormProps = {
  oobCode: string;
};

type VerificationStatus = "loading" | "success" | "error";

const VerifyEmailPage = ({ oobCode }: VerifyEmailFormProps) => {
  const userService = useUserService();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState<VerificationStatus>("loading");
  const verificationComplete = useRef(false);

  const handleRedirect = () => {
    navigate(verificationStatus === "success" ? "/" : "/login");
  };

  const content = {
    loading: (
      <>
        <CircularProgress size={60} sx={{ mb: 3 }} />
        <Typography variant="h6" gutterBottom>
          Verifying your email...
        </Typography>
        <Typography color="text.secondary">Please wait while we confirm your email address</Typography>
      </>
    ),
    success: (
      <>
        <CheckCircleOutlineIcon sx={{ fontSize: 60, color: "success.main", mb: 3 }} />
        <Typography variant="h6" gutterBottom>
          Email verified successfully!
        </Typography>
        <Typography color="text.secondary" gutterBottom>
          Your email has been verified. You can now access all features of your account.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleRedirect} sx={{ mt: 3 }}>
          Return to Login
        </Button>
      </>
    ),
    error: (
      <>
        <ErrorOutlineIcon sx={{ fontSize: 60, color: "error.main", mb: 3 }} />
        <Typography variant="h6" gutterBottom color="error">
          Verification failed
        </Typography>
        <Typography color="text.secondary" gutterBottom>
          The verification link appears to be invalid or has expired.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleRedirect} sx={{ mt: 3 }}>
          Return to login
        </Button>
      </>
    ),
  };

  useEffect(() => {
    if (!oobCode) {
      navigate("/login");
      return;
    }

    const verifyEmail = async () => {
      if (verificationComplete.current) {
        return;
      }

      try {
        await userService.verifyEmail(oobCode);
        setVerificationStatus("success");
        verificationComplete.current = true;
      } catch (err) {
        if (!verificationComplete.current) {
          setVerificationStatus("error");
        }
      }
    };

    void verifyEmail();
  }, [oobCode, userService, navigate]);

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Paper
        elevation={0}
        sx={{
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {content[verificationStatus]}
        </Box>
      </Paper>
    </Container>
  );
};

export default VerifyEmailPage;
