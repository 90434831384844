import {
  AddCategoriesFromCatalogueRequest,
  AddCategoriesFromCatalogueResponse,
  AddUserRequest,
  AddUserResponse,
  ClientReminderRequest,
  ClientReminderResponse,
  CopyPeriodRequest,
  CopyPeriodResponse,
  DeleteFileRequest,
  DeleteFileResponse,
  DropShoeboxFileRequest,
  DropShoeboxFileResponse,
  ModifyCatalogueRequest,
  ModifyCatalogueResponse,
  OrderCatalogueCategoryRequest,
  OrderCatalogueCategoryResponse,
  RegisterUserRequest,
  RegisterUserResponse,
  RemoveUserRequest,
  RemoveUserResponse,
  ResendInviteRequest,
  ResendInviteResponse,
  UpdateOrganisationAdminRequest,
  UpdateOrganisationAdminResponse,
  WorkingPapersRequest,
  WorkingPapersResponse,
} from "@taxy/common";
import { getApp } from "firebase/app";
import { Functions, getFunctions, httpsCallable } from "firebase/functions";
import { useMemo } from "react";

export class HttpFunctions {
  constructor(readonly functions: Functions) {}

  public workingPapers = httpsCallable<WorkingPapersRequest, WorkingPapersResponse>(this.functions, "workingPapers");

  public addCategoriesFromCatalogue = httpsCallable<
    AddCategoriesFromCatalogueRequest,
    AddCategoriesFromCatalogueResponse
  >(this.functions, "addCategoriesFromCatalogue");

  // add/delete requirements and categories
  public modifyCatalogue = httpsCallable<ModifyCatalogueRequest, ModifyCatalogueResponse>(
    this.functions,
    "modifyCatalogue"
  );

  // TODO replace call in Catalogue.tsx with this one - also needs fixing because it doesn't work properly
  public orderCatalogueCategory = httpsCallable<OrderCatalogueCategoryRequest, OrderCatalogueCategoryResponse>(
    this.functions,
    "orderCategory"
  );

  public removeUser = httpsCallable<RemoveUserRequest, RemoveUserResponse>(this.functions, "removeUser");

  public addUser = httpsCallable<AddUserRequest, AddUserResponse>(this.functions, "addUser");

  public updateOrganisationAdminUser = httpsCallable<UpdateOrganisationAdminRequest, UpdateOrganisationAdminResponse>(
    this.functions,
    "updateOrganisationAdminUser"
  );

  public registerUser = httpsCallable<RegisterUserRequest, RegisterUserResponse>(this.functions, "registerUser");

  public dropShoeboxFile = httpsCallable<DropShoeboxFileRequest, DropShoeboxFileResponse>(
    this.functions,
    "dropShoeboxFile"
  );

  public resendInvite = httpsCallable<ResendInviteRequest, ResendInviteResponse>(this.functions, "resendInvite");

  public remindClient = httpsCallable<ClientReminderRequest, ClientReminderResponse>(this.functions, "remindClient");

  public copyPeriod = httpsCallable<CopyPeriodRequest, CopyPeriodResponse>(this.functions, "copyPeriod");

  public deleteFile = httpsCallable<DeleteFileRequest, DeleteFileResponse>(this.functions, "deleteFile");
}

const useHttpFunctions = (): HttpFunctions => {
  const functions = getFunctions(getApp(), "australia-southeast1");
  return useMemo(() => new HttpFunctions(functions), [functions]);
};

export default useHttpFunctions;
