import { Check as CheckIcon, Error as ErrorIcon } from "@mui/icons-material";
import {
  DialogContentText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import useHttpFunctions from "../../../hooks/HttpFunctions";
import usePeriodService from "../../../hooks/PeriodService";
import { ClientType } from "../../../types";
import ConfirmationDialog from "../../dialogs/ConfirmationDialog";

type RolloverStatus = { client: ClientType; success: boolean; reason?: string };

type RolloverClientsDialogProps = {
  clients: ClientType[];
  open: boolean;
  onClose: (isConfirmed: boolean) => void;
};

const RolloverClientsDialog = ({ open, onClose, clients }: RolloverClientsDialogProps) => {
  const periodService = usePeriodService();
  const httpFunctions = useHttpFunctions();

  const [rolloverStatuses, setRolloverStatuses] = useState<RolloverStatus[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleOnConfirm = async () => {
    if (isConfirmed) {
      onClose(true);
      return;
    }

    setIsProcessing(true);

    const rollOverPromises = clients.map((client) => {
      const nextFiscalYear = periodService.getNextFiscalYear(client.activePeriodName as string);
      return httpFunctions.copyPeriod({
        organisationId: client.organisationId,
        clientId: client.clientId,
        copyFromPeriodId: client.activePeriodId as string,
        name: nextFiscalYear,
        makeActive: true,
      });
    });

    const responses = await Promise.allSettled(rollOverPromises);

    const statuses = responses.map((response, index) => ({
      client: clients[index] as ClientType,
      success: response.status === "fulfilled" && response.value.data.success,
      reason: response.status === "fulfilled" && !response.value.data.success ? response.value.data.message : undefined,
    }));

    setRolloverStatuses(statuses);
    setIsProcessing(false);
    setIsConfirmed(true);
  };

  const handleOnClose = () => {
    if (isProcessing) return;
    if (isConfirmed) {
      onClose(true);
    } else {
      onClose(false);
    }
  };

  // reset fields when dialog is opened
  useEffect(() => {
    if (open) {
      setRolloverStatuses([]);
      setIsProcessing(false);
      setIsConfirmed(false);
    }
  }, [open]);

  const dialogContent = (
    <>
      <DialogContentText sx={{ mb: 2 }}>
        {isConfirmed
          ? "Rollover process completed. Please review the results:"
          : "You are rolling over these clients to the next period:"}
      </DialogContentText>

      <TableContainer component={Paper} square elevation={0} aria-label="rollover-clients-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ p: 0.5 }}>Client name</TableCell>
              <TableCell sx={{ p: 0.5 }}>Current active period</TableCell>
              <TableCell sx={{ p: 0.5 }}>Next active period</TableCell>
              {isConfirmed && <TableCell sx={{ p: 0.5 }}>Status</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {(isConfirmed
              ? rolloverStatuses
              : clients.map((client) => ({ client, success: false, reason: undefined }))
            ).map(({ client, success, reason }) => {
              const nextFiscalYear = periodService.getNextFiscalYear(client.activePeriodName as string);
              return (
                <TableRow key={client.clientId}>
                  <TableCell sx={{ p: 0.5 }}>{client.name}</TableCell>
                  <TableCell sx={{ p: 0.5 }}>{client.activePeriodName}</TableCell>
                  <TableCell sx={{ p: 0.5 }}>{nextFiscalYear}</TableCell>
                  {isConfirmed && (
                    <TableCell sx={{ p: 0.5 }}>
                      {success ? (
                        <CheckIcon color="success" />
                      ) : (
                        <Tooltip title={reason || "An unexpected error occurred"}>
                          <ErrorIcon color="error" />
                        </Tooltip>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  return (
    <ConfirmationDialog
      open={open}
      title={isConfirmed ? "Rollover results" : "Rollover clients"}
      content={dialogContent}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      confirmButtonLabel={isConfirmed ? "Done" : isProcessing ? "Processing..." : "Confirm rollover"}
      cancelButtonLabel={isConfirmed || isProcessing ? undefined : "Cancel"}
      maxWidth="sm"
    />
  );
};

export default RolloverClientsDialog;
