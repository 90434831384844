import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState } from "react";
import { useApplicationState } from "../../hooks/ApplicationState";
import useLocalStorageService from "../../hooks/LocalStorageService";

interface TwoFactorDialogProps {
  open: boolean;
  onClose: (enableTwoFactor: boolean) => void;
}

const SuggestTwoFactorAuthDialog = ({ open, onClose }: TwoFactorDialogProps) => {
  const { userId } = useApplicationState();
  const localStorageService = useLocalStorageService();

  const [dontAskAgain, setDontAskAgain] = useState(false);

  const handleClose = (_event: object, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") return;
    handleResponse(false);
  };

  const handleResponse = (enableTwoFactor: boolean) => {
    if (!enableTwoFactor && userId && dontAskAgain) {
      localStorageService.setDontAskAgainTwoFactorAuth(userId, true);
    }
    onClose(enableTwoFactor);
  };

  return (
    <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
      <DialogTitle>Enable two-factor authentication?</DialogTitle>
      <DialogContent sx={{ my: 2 }}>
        <DialogContentText>
          Would you like to enable two-factor authentication via SMS using your Australian mobile number?
        </DialogContentText>

        <FormControlLabel
          label="Don't ask me again"
          control={
            <Checkbox checked={dontAskAgain} onChange={(e) => setDontAskAgain(e.target.checked)} color="primary" />
          }
          sx={{ mt: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleResponse(false)} color="primary">
          Not now
        </Button>
        <Button onClick={() => handleResponse(true)} variant="contained" color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuggestTwoFactorAuthDialog;
