import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { useApplicationState } from "../../hooks/ApplicationState";
import useUserService from "../../hooks/UserService";

interface RequestVerifyEmailDialogProps {
  open: boolean;
  onClose: (requested: boolean) => void;
}

const RequestVerifyEmailDialog = ({ open, onClose }: RequestVerifyEmailDialogProps) => {
  const { user } = useApplicationState();
  const userService = useUserService();

  const [emailSent, setEmailSent] = useState(false);

  const handleSendVerification = async () => {
    try {
      await userService.requestEmailVerification();
      setEmailSent(true);
    } catch (error) {
      console.error("Failed to send verification email:", error);
    }
  };

  const handleClose = (_event: object, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") return;
    handleResponse(false);
  };

  const handleResponse = (requested: boolean) => {
    onClose(requested);
  };

  if (emailSent) {
    return (
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
        <DialogTitle>Verification email sent</DialogTitle>
        <DialogContent sx={{ my: 2 }}>
          <DialogContentText>
            We've sent a verification email to <b>{user?.email}</b> Please check your inbox and follow the instructions
            to verify your account.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleResponse(true)} variant="contained" color="primary">
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
      <DialogTitle>Verify your email</DialogTitle>
      <DialogContent sx={{ my: 2 }}>
        <DialogContentText>
          Your email address must be verified before enabling two-factor authentication. It looks like you haven't
          verified it yet. Would you like to verify it now?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleResponse(false)} color="primary">
          Not now
        </Button>
        <Button onClick={handleSendVerification} variant="contained" color="primary">
          Send verification email
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RequestVerifyEmailDialog;
