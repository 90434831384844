import { GroupRemove } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Box, IconButton, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { orderBy } from "lodash";
import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import { useApplicationState } from "../../../hooks/ApplicationState";
import useInviteService from "../../../hooks/InviteService";
import useUserService from "../../../hooks/UserService";
import { InviteType, UserType } from "../../../types";
import ResendInviteDialog from "../../dialogs/ResendInviteDialog";
import AddClientUserDialog from "./AddClientUserDialog";
import AddToAnotherClientDialog from "./AddToAnotherClientDialog";
import RemoveClientUserDialog from "./RemoveClientUserDialog";
import InviteExpiry from "../../InviteExpiry";

type ClientUsersProps = {
  organisationId: string;
  clientId: string;
};

const ClientUsers = ({ organisationId, clientId }: ClientUsersProps) => {
  const userService = useUserService();
  const inviteService = useInviteService();
  const { setSystemMessage } = useApplicationState();

  const [users, setUsers] = useState<UserType[]>([]);
  const [invites, setInvites] = useState<InviteType[]>([]);

  // menu state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuInvite, setMenuInvite] = useState<InviteType>();
  const [menuUser, setMenuUser] = useState<UserType>();
  const menuOpen = Boolean(anchorEl);

  // dialog state
  const [addClientUserDialogOpen, setAddClientUserDialogOpen] = useState<boolean>(false);
  const [addToAnotherClientDialogOpen, setAddToAnotherClientDialogOpen] = useState<boolean>(false);
  const [removeClientUserDialogOpen, setRemoveClientUserDialogOpen] = useState<boolean>(false);
  const [resendInviteDialogOpen, setResendInviteDialogOpen] = useState<boolean>(false);

  const loadUsersAndInvites = useCallback(async () => {
    const [userResults, inviteResults] = await Promise.all([
      userService.listClientUsers(organisationId, clientId),
      inviteService.getClientInvites(organisationId, clientId),
    ]);

    setUsers(userResults);
    setInvites(inviteResults);
  }, [organisationId, clientId, userService, inviteService]);

  useEffect(() => {
    void loadUsersAndInvites();
  }, [loadUsersAndInvites]);

  const handleMenuInviteClick = (event: MouseEvent<HTMLElement>, invite: InviteType) => {
    setMenuUser(undefined);
    setMenuInvite(invite);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuUserClick = (event: MouseEvent<HTMLElement>, user: UserType) => {
    setMenuInvite(undefined);
    setMenuUser(user);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddToAnotherClientDialogOpen = () => {
    setAddToAnotherClientDialogOpen(true);
    handleMenuClose();
  };

  const handleAddToAnotherClientDialogClose = (message?: string) => {
    setAddToAnotherClientDialogOpen(false);
    if (message) {
      setSystemMessage(message);
      void loadUsersAndInvites();
    }
  };

  const handleRemoveClientUserDialogOpen = () => {
    setRemoveClientUserDialogOpen(true);
    handleMenuClose();
  };

  const handleRemoveClientUserDialogClose = (message?: string) => {
    setRemoveClientUserDialogOpen(false);
    if (message) {
      setSystemMessage(message);
      void loadUsersAndInvites();
    }
  };

  const handleResendInviteDialogOpen = () => {
    setResendInviteDialogOpen(true);
    handleMenuClose();
  };

  const handleResendInviteDialogClose = (message?: string) => {
    setResendInviteDialogOpen(false);
    if (message) {
      setSystemMessage(message);
      void loadUsersAndInvites();
    }
  };

  const handleAddUserDialogOpen = () => {
    setAddClientUserDialogOpen(true);
  };

  const handleAddClientUserDialogClose = (message?: string) => {
    void loadUsersAndInvites();
    setAddClientUserDialogOpen(false);
    setSystemMessage(message);
  };

  return (
    <Grid container sx={{ borderTop: "solid 1px", borderColor: "neutral.light" }}>
      <Grid item xs={12} sx={{ m: 2 }}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6">Client users</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontStyle: "italic" }}>
              External users who have access to this client's data.
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <Button variant="contained" color="primary" startIcon={<PersonAddIcon />} onClick={handleAddUserDialogOpen}>
              Add user
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ mx: 2 }}>
        <TableContainer component={Paper} square elevation={0}>
          <Table aria-label="Users table">
            <TableHead>
              <TableRow>
                <TableCell>First name</TableCell>
                <TableCell>Last name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Invite expiry</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {/* USERS */}
              {orderBy(users, (user) => user.firstName).map((user) => (
                <TableRow key={user.uid}>
                  <TableCell>{user.firstName}</TableCell>
                  <TableCell>{user.lastName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell
                    sx={{
                      color: "success.main",
                    }}
                  >
                    Accepted
                  </TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>
                    <Box>
                      <IconButton onClick={(event) => handleMenuUserClick(event, user)}>
                        <MoreVertIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}

              {/* INVITES */}
              {orderBy(invites, (invite) => invite.invitee.firstName).map((invite) => (
                <TableRow key={invite.id}>
                  <TableCell>{invite.invitee.firstName}</TableCell>
                  <TableCell>{invite.invitee.lastName}</TableCell>
                  <TableCell>{invite.invitee.email}</TableCell>
                  <TableCell
                    sx={{
                      color: invite.status === "PENDING" ? "warning.main" : "error.main",
                    }}
                  >
                    {invite.status === "PENDING" ? "Pending" : "Expired"}
                  </TableCell>
                  <TableCell>
                    <InviteExpiry invite={invite} />
                  </TableCell>
                  <TableCell>
                    <Box>
                      <IconButton onClick={(event) => handleMenuInviteClick(event, invite)}>
                        <MoreVertIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
          {menuInvite && (
            <Box>
              <MenuItem dense onClick={() => handleResendInviteDialogOpen()}>
                <ListItemIcon>
                  <EmailIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Resend invite email</ListItemText>
              </MenuItem>
            </Box>
          )}

          {menuUser && (
            <Box>
              <MenuItem dense onClick={() => handleAddToAnotherClientDialogOpen()}>
                <ListItemIcon>
                  <GroupAddIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Add to another client</ListItemText>
              </MenuItem>
              <MenuItem disabled={users.length < 2} dense onClick={() => handleRemoveClientUserDialogOpen()}>
                <ListItemIcon>
                  <GroupRemove fontSize="small" />
                </ListItemIcon>
                <ListItemText>Remove user from client</ListItemText>
              </MenuItem>
            </Box>
          )}
        </Menu>
      </Grid>

      <AddClientUserDialog
        organisationId={organisationId}
        clientId={clientId}
        open={addClientUserDialogOpen}
        onClose={handleAddClientUserDialogClose}
      />

      {menuUser && (
        <RemoveClientUserDialog
          organisationId={organisationId}
          clientId={clientId}
          user={menuUser}
          open={removeClientUserDialogOpen}
          onClose={handleRemoveClientUserDialogClose}
        />
      )}

      {menuUser && (
        <AddToAnotherClientDialog
          organisationId={organisationId}
          user={menuUser}
          open={addToAnotherClientDialogOpen}
          onClose={handleAddToAnotherClientDialogClose}
        />
      )}

      {menuInvite && (
        <ResendInviteDialog
          inviteId={menuInvite.id}
          open={resendInviteDialogOpen}
          onClose={handleResendInviteDialogClose}
        />
      )}
    </Grid>
  );
};

export default ClientUsers;
