import { difference, times } from "lodash";
import moment from "moment";
import { useMemo } from "react";

export class PeriodService {
  getPeriods(): string[] {
    const currentYear = moment().year();
    return times(5, (i) => {
      return `FY ${currentYear + 1 - i}`;
    });
  }

  getAvailablePeriods(existingPeriods: string[]) {
    const allPeriods = this.getPeriods();
    return difference(allPeriods, existingPeriods);
  }

  getDefaultPeriod(): string {
    return `FY ${moment().year()}`;
  }

  getNextFiscalYear = (fy: string): string => {
    const year = Number(fy.replace(/\D/g, "")); // Extract numbers
    return isNaN(year) ? fy : `FY ${year + 1}`;
  };
}

const usePeriodService = () => {
  return useMemo(() => {
    return new PeriodService();
  }, []);
};

export default usePeriodService;
