import { RoleType } from "@taxy/common";
import {
  applyActionCode,
  Auth,
  checkActionCode,
  confirmPasswordReset,
  EmailAuthProvider,
  getAuth,
  getMultiFactorResolver,
  multiFactor,
  MultiFactorError,
  PhoneAuthProvider,
  PhoneMultiFactorAssertion,
  PhoneMultiFactorGenerator,
  reauthenticateWithCredential,
  RecaptchaVerifier,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { getDoc, query, where } from "firebase/firestore";
import { chain, includes } from "lodash";
import { useMemo } from "react";
import { UserType, VerificationMultiFactorAuth } from "../types";
import { useApplicationState } from "./ApplicationState";
import useFirestoreService, { FirestoreService } from "./FirestoreService";
import useHttpFunctions, { HttpFunctions } from "./HttpFunctions";
import ServiceError from "./ServiceError";

const ACCOUNTANT_ROLE: RoleType = "ACCOUNTANT";

export class UserService {
  constructor(
    readonly firestoreService: FirestoreService,
    readonly auth: Auth,
    readonly httpFunctions: HttpFunctions,
    readonly recaptcha?: RecaptchaVerifier
  ) {}

  public async signIn(email: string, password: string) {
    await signInWithEmailAndPassword(this.auth, email, password);
  }

  public async reauthenticate(password: string) {
    try {
      if (!this.auth.currentUser) {
        throw new ServiceError("No authenticated user to reauthenticate.");
      }

      const currentUser = this.auth.currentUser;

      if (!currentUser.email) {
        throw new ServiceError("No email address associated with the current user.");
      }

      const credential = EmailAuthProvider.credential(currentUser.email, password);

      return await reauthenticateWithCredential(currentUser, credential);
    } catch (e) {
      console.error(e);
      throw e; // Rethrow the Firebase error to be handled by the caller
    }
  }

  public async signOut() {
    return signOut(this.auth);
  }

  public async getUser(uid: string): Promise<UserType | undefined> {
    try {
      const documentReference = this.firestoreService.getUserDocument(uid);
      const documentSnapshot = await getDoc(documentReference);
      return documentSnapshot.exists() ? (documentSnapshot.data() as UserType) : undefined;
    } catch (e) {
      console.error(e);
      throw new ServiceError(`Unable to get user ${uid}`);
    }
  }

  public async listClientUsers(organisationId: string, clientId: string): Promise<UserType[]> {
    const users = await this.listOrganisationUsers([organisationId]);
    return chain(users)
      .filter((user) => includes(user.clientIds, clientId))
      .orderBy((user) => user.firstName, "asc")
      .value();
  }

  public async listAccountantUsers(organisationIds: string[]): Promise<UserType[]> {
    const users = await this.listOrganisationUsers(organisationIds);
    return chain(users)
      .filter((user) => includes(user.roles, ACCOUNTANT_ROLE))
      .orderBy((user) => user.firstName, "asc")
      .value();
  }

  /**
   * Returns users for any of the given organisations. Firestore queries can only have one "array-contains-any" clause
   * so this function can be used to get all organisation users and the list can then be filtered further in memory
   * if necessary.
   */
  public async listOrganisationUsers(organisationIds: string[]): Promise<UserType[]> {
    const usersCollection = this.firestoreService.getUsersCollection();
    const snapshots = await this.firestoreService.getDocs(
      query(usersCollection, where("organisationIds", "array-contains-any", organisationIds))
    );
    return snapshots.docs.map((snapshot) => snapshot.data() as UserType);
  }

  public async registerNewUser(
    firstName: string,
    lastName: string,
    email: string,
    organisationName: string,
    password: string
  ): Promise<string | undefined> {
    const { data } = await this.httpFunctions.registerUser({
      userType: "NEW_USER",
      firstName,
      lastName,
      email,
      organisationName,
      password,
    });
    if (!data.success) {
      throw new ServiceError(data.message);
    } else {
      return data.message;
    }
  }

  public async registerInvitedUser(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    inviteCode: string
  ): Promise<string | undefined> {
    const { data } = await this.httpFunctions.registerUser({
      userType: "INVITED_USER",
      firstName,
      lastName,
      email,
      password,
      inviteCode,
    });

    if (!data.success) {
      throw new ServiceError(data.message);
    } else {
      return data.message;
    }
  }

  public async requestPasswordReset(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

  public async resetPassword(oobCode: string, newPassword: string) {
    return confirmPasswordReset(this.auth, oobCode, newPassword);
  }

  public async revertMultiFactorAuth(oobCode: string, email: string) {
    const actionCodeInfo = await checkActionCode(this.auth, oobCode);
    if (
      actionCodeInfo != null &&
      actionCodeInfo.operation === "REVERT_SECOND_FACTOR_ADDITION" &&
      actionCodeInfo.data.email === email
    ) {
      return applyActionCode(this.auth, oobCode);
    } else {
      throw new ServiceError("Invalid request");
    }
  }

  public async requestEmailVerification() {
    const loggedInUser = this.auth.currentUser;
    if (loggedInUser) {
      return sendEmailVerification(loggedInUser);
    } else {
      throw new ServiceError("Cannot send email verification, the user is not logged in");
    }
  }

  public async verifyEmail(oobCode: string) {
    return applyActionCode(this.auth, oobCode);
  }

  public async verifyPhoneNumber(phoneNumber: string): Promise<string> {
    if (!this.auth.currentUser) {
      throw new ServiceError("Authentication session is not initialized.");
    }

    if (!this.recaptcha) {
      throw new ServiceError("Recaptcha is not initialized.");
    }

    try {
      const phoneAuthProvider = new PhoneAuthProvider(this.auth);
      const session = await multiFactor(this.auth.currentUser).getSession();

      const phoneInfoOptions = {
        phoneNumber,
        session,
      };

      return await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.recaptcha);
    } catch (e) {
      console.error(`Failed to verify phone number for ${phoneNumber}:`, e);
      throw e; // Rethrow the Firebase error to be handled by the caller
    }
  }

  public async verifyUserMultiFactorAuth(
    error: MultiFactorError,
    selectedIndex = 0 // Default to use the first factor, supporting only SMS for now
  ): Promise<VerificationMultiFactorAuth | null> {
    if (!this.recaptcha) {
      throw new ServiceError("Recaptcha is not initialized.");
    }

    try {
      const resolver = getMultiFactorResolver(this.auth, error);
      const session = resolver.session;
      const multiFactorHint = resolver.hints[selectedIndex];

      if (!multiFactorHint) {
        throw new ServiceError("Invalid factor selection. No factor available at the provided index.");
      }

      if (multiFactorHint.factorId !== PhoneMultiFactorGenerator.FACTOR_ID) {
        throw new ServiceError("Selected factor is not an SMS-based multi-factor authentication method.");
      }

      const phoneOptions = {
        multiFactorHint,
        session,
      };

      const phoneAuthProvider = new PhoneAuthProvider(this.auth);
      const verificationCodeId = await phoneAuthProvider.verifyPhoneNumber(phoneOptions, this.recaptcha);

      return { verificationCodeId, resolver };
    } catch (e) {
      console.error("Failed to verify user multi-factor authentication:", e);
      return null;
    }
  }

  public async verifySmsTwoFactorSignIn(
    VerificationMultiFactorAuth: VerificationMultiFactorAuth,
    verificationCode: string
  ): Promise<boolean> {
    const { verificationCodeId, resolver } = VerificationMultiFactorAuth;

    try {
      const phoneMultiFactorAssertion = this.createPhoneMultiFactorAssertion(verificationCodeId, verificationCode);
      await resolver.resolveSignIn(phoneMultiFactorAssertion);
      return true;
    } catch (e) {
      console.error("Error verifying SMS-based two-factor authentication during user sign-in:", e);
      return false;
    }
  }

  public async enrollUserToSmsTwoFactorAuth(verificationCodeId: string, verificationCode: string): Promise<boolean> {
    if (!this.auth.currentUser) {
      throw new ServiceError("No authenticated user to enroll in SMS TwoFactor authentication.");
    }

    const user = this.auth.currentUser;
    const userId = user.uid;

    try {
      const phoneMultiFactorAssertion = this.createPhoneMultiFactorAssertion(verificationCodeId, verificationCode);
      const multiFactorUser = multiFactor(user);
      await multiFactorUser.enroll(phoneMultiFactorAssertion, "Personal Phone Number");
      return true;
    } catch (e) {
      console.error(`Failed to enroll user to SMS TwoFactor for userId ${userId}:`, e);
      return false;
    }
  }

  public async disableUserFromSmsTwoFactorAuth() {
    if (!this.auth.currentUser) {
      throw new ServiceError("No authenticated user to enroll in SMS TwoFactor authentication.");
    }

    const user = this.auth.currentUser;
    const userId = user.uid;

    try {
      const multiFactorUser = multiFactor(user);
      const enrolledFactor = multiFactorUser.enrolledFactors[0]; //the first factor as supporting only SMS for now
      if (!enrolledFactor) throw new ServiceError(`User update failed for userId: ${userId}`);
      await multiFactor(user).unenroll(enrolledFactor);
      return true;
    } catch (e) {
      console.error(`Failed to disable user from SMS TwoFactor for userId ${userId}:`, e);
      throw e; // Rethrow the Firebase error to be handled by the caller
    }
  }

  private createPhoneMultiFactorAssertion(
    verificationCodeId: string,
    verificationCode: string
  ): PhoneMultiFactorAssertion {
    try {
      const phoneAuthCredential = PhoneAuthProvider.credential(verificationCodeId, verificationCode);
      return PhoneMultiFactorGenerator.assertion(phoneAuthCredential);
    } catch (e) {
      console.error(`Failed to create multi-factor assertion with verificationCodeId ${verificationCodeId}:`, e);
      throw new ServiceError(
        "Unable to create multi-factor assertion. Please check the verification code and try again."
      );
    }
  }
}

const useUserService = () => {
  const { recaptcha } = useApplicationState();
  const firestoreService = useFirestoreService();
  const auth = getAuth();
  const httpFunctions = useHttpFunctions();

  return useMemo(() => {
    return new UserService(firestoreService, auth, httpFunctions, recaptcha);
  }, [firestoreService, auth, httpFunctions, recaptcha]);
};

export default useUserService;
