import { yupResolver } from "@hookform/resolvers/yup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FirebaseError } from "firebase/app";
import { MultiFactorError } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useApplicationState } from "../../hooks/ApplicationState";
import useLocalStorageService from "../../hooks/LocalStorageService";
import useUserService from "../../hooks/UserService";
import { VerificationMultiFactorAuth } from "../../types";
import SubmitButton from "../button/SubmitButton";
import BaseAuthPage from "./BaseAuthPage";
import RequestVerifyEmailDialog from "./RequestVerifyEmailDialog";
import SuggestTwoFactorAuthDialog from "./SuggestTwoFactorAuthDialog";
import TwoFactorAuthDialog from "./TwoFactorAuthDialog";

const formSchema = yup
  .object({
    email: yup.string().trim().email("Email must be a valid email address").required("Email is required"),
    password: yup.string().required("Password is required"),
    rememberMe: yup.boolean(),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

const LoginPage = () => {
  const { userId, signInSecondFactor, emailVerified } = useApplicationState();
  const userService = useUserService();
  const localStorageService = useLocalStorageService();
  const location = useLocation();
  const navigate = useNavigate();

  const [suggestTwoFactorAuthDialogOpen, setSuggestTwoFactorAuthDialogOpen] = useState<boolean>(false);
  const [twoFactorAuthDialogOpen, setTwoFactorAuthDialogOpen] = useState<boolean>(false);
  const [requestVerifyEmailDialogOpen, setRequestVerifyEmailDialogOpen] = useState<boolean>(false);

  const [hasShownTwoFactorDialog, setHasShownTwoFactorDialog] = useState<boolean>(false);
  const [verificationMultiFactorAuth, setVerificationMultiFactorAuth] = useState<VerificationMultiFactorAuth | null>(
    null
  );

  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormType>({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async (data: FormType) => {
    const { email, password, rememberMe } = data;
    try {
      if (rememberMe) {
        localStorageService.setRememberMe(rememberMe);
      } else {
        localStorageService.removeRememberMe();
      }

      await userService.signIn(email, password);
    } catch (e) {
      if (e instanceof FirebaseError && e.code === "auth/multi-factor-auth-required") {
        const verificationMultiFactorAuth = await userService.verifyUserMultiFactorAuth(e as MultiFactorError);
        if (verificationMultiFactorAuth) {
          setVerificationMultiFactorAuth(verificationMultiFactorAuth);
          setTwoFactorAuthDialogOpen(true);
        }
      } else {
        setError("password", {
          type: "custom",
          message: "There was an error logging in. Check your email address and password and try again.",
        });
      }
    }
  };

  const onSuggestTwoFactorAuthDialogClose = (enableTwoFactor: boolean) => {
    setSuggestTwoFactorAuthDialogOpen(false);
    if (enableTwoFactor) {
      if (emailVerified) {
        setTwoFactorAuthDialogOpen(true);
      } else {
        setRequestVerifyEmailDialogOpen(true);
      }
    }
  };

  const onTwoFactorAuthDialogClose = (verified: boolean) => {
    setTwoFactorAuthDialogOpen(false);
    if (verified) {
      setHasShownTwoFactorDialog(true);
    } else {
      navigate("/logout");
    }
  };

  const onRequestVerifyEmailDialogClose = (requested: boolean) => {
    setRequestVerifyEmailDialogOpen(false);
    if (requested) {
      navigate("/logout");
    }
  };

  useEffect(() => {
    if (userId) {
      const isDialogClose =
        !suggestTwoFactorAuthDialogOpen && !twoFactorAuthDialogOpen && !requestVerifyEmailDialogOpen;

      const dontAskAgain = localStorageService.getDontAskAgainTwoFactorAuth(userId);

      const isSignInSecondFactor = signInSecondFactor === "phone";

      if (!isSignInSecondFactor && !hasShownTwoFactorDialog && isDialogClose && !dontAskAgain) {
        setSuggestTwoFactorAuthDialogOpen(true);
        setHasShownTwoFactorDialog(true);
      } else if (isDialogClose || dontAskAgain) {
        const redirectTo = location?.state?.redirectTo || "/";
        navigate(redirectTo);
      }
    }
  }, [
    userId,
    signInSecondFactor,
    suggestTwoFactorAuthDialogOpen,
    twoFactorAuthDialogOpen,
    hasShownTwoFactorDialog,
    location,
    localStorageService,
    requestVerifyEmailDialogOpen,
    navigate,
  ]);

  return (
    <>
      <BaseAuthPage
        content={
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="center">
                <Typography variant="h6">Login</Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  label="Email address"
                  autoComplete="username"
                  autoFocus
                  error={errors?.email?.message != null}
                  helperText={errors.email?.message}
                  {...register("email")}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="password"
                  error={errors?.password?.message != null}
                  helperText={errors.password?.message}
                  {...register("password")}
                />
              </Grid>

              <Grid item xs={12} textAlign="center" sx={{ pt: 0 }}>
                <FormControlLabel
                  label={<Typography variant="caption">Remember me for 30 days on this computer</Typography>}
                  control={<Checkbox size="small" {...register("rememberMe")} />}
                  sx={{ color: "text.secondary" }}
                />
              </Grid>

              <Grid item xs={12} textAlign="center">
                <SubmitButton loading={isSubmitting} label="Login" />
              </Grid>
            </Grid>
          </form>
        }
        footer={
          <>
            Forgot your password? Click <Link to="/reset-password">here</Link> to reset it.
          </>
        }
      />

      <SuggestTwoFactorAuthDialog open={suggestTwoFactorAuthDialogOpen} onClose={onSuggestTwoFactorAuthDialogClose} />

      <TwoFactorAuthDialog
        open={twoFactorAuthDialogOpen}
        onClose={onTwoFactorAuthDialogClose}
        verificationMultiFactorAuth={verificationMultiFactorAuth}
      />

      <RequestVerifyEmailDialog open={requestVerifyEmailDialogOpen} onClose={onRequestVerifyEmailDialogClose} />
    </>
  );
};

export default LoginPage;
