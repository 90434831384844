import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { FirebaseError } from "firebase/app";
import { MultiFactorError } from "firebase/auth";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import useUserService from "../../hooks/UserService";
import { VerificationMultiFactorAuth } from "../../types";
import SubmitButton from "../button/SubmitButton";
import TwoFactorAuthDialog from "./TwoFactorAuthDialog";

const formSchema = yup
  .object({
    password: yup.string().required("Password is required"),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

interface ReAuthenticationDialogProps {
  open: boolean;
  onClose: (reauthenticated: boolean) => void;
  onConfirm: (password: string) => Promise<void>;
  fromEnableTwoFactor?: boolean;
}

export const ReAuthenticationDialog = ({
  open,
  fromEnableTwoFactor,
  onClose,
  onConfirm,
}: ReAuthenticationDialogProps) => {
  const userService = useUserService();

  const [twoFactorAuthDialogOpen, setTwoFactorAuthDialogOpen] = useState<boolean>(false);

  const [verificationMultiFactorAuth, setVerificationMultiFactorAuth] = useState<VerificationMultiFactorAuth | null>(
    null
  );

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormType>({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async (data: FormType) => {
    try {
      await onConfirm(data.password);
    } catch (e) {
      if (e instanceof FirebaseError && e.code === "auth/multi-factor-auth-required") {
        const verificationMultiFactorAuth = await userService.verifyUserMultiFactorAuth(e as MultiFactorError);
        if (verificationMultiFactorAuth) {
          setVerificationMultiFactorAuth(verificationMultiFactorAuth);
          setTwoFactorAuthDialogOpen(true);
        }
      } else {
        setError("password", {
          type: "custom",
          message: "There was an error re-authenticating. Check your password and try again.",
        });
      }
    }
  };

  const handleClose = (verified: boolean) => {
    reset();
    onClose(verified);
  };

  const onTwoFactorAuthDialogClose = (verified: boolean) => {
    setTwoFactorAuthDialogOpen(false);
    if (verified) {
      handleClose(true);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleClose(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Re-authentication required</DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  For security reasons, please enter your password to continue.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    sx={{ m: 0 }}
                    autoFocus
                    fullWidth
                    margin="dense"
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    error={errors?.password?.message != null}
                    helperText={errors.password?.message}
                    {...register("password")}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => handleClose(false)} variant="outlined" color="secondary">
              Cancel
            </Button>
            <SubmitButton loading={isSubmitting} label="Reauthenticate" />
          </DialogActions>
        </form>
      </Dialog>

      {!fromEnableTwoFactor && (
        <TwoFactorAuthDialog
          open={twoFactorAuthDialogOpen}
          onClose={onTwoFactorAuthDialogClose}
          verificationMultiFactorAuth={verificationMultiFactorAuth}
        />
      )}
    </>
  );
};

export default ReAuthenticationDialog;
