import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import useUserService from "../../hooks/UserService";
import { InviteType, UserType } from "../../types";
import Button from "@mui/material/Button";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CheckIcon from "@mui/icons-material/Check";
import { useApplicationState } from "../../hooks/ApplicationState";
import AddTeamUserDialog from "./AddTeamUserDialog";
import { Box, IconButton, MenuItem } from "@mui/material";
import { includes, orderBy } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
import ListItemText from "@mui/material/ListItemText";
import RemoveTeamMemberDialog from "./RemoveTeamMemberDialog";
import RemoveTeamMemberAdminDialog from "./RemoveTeamMemberAdminDialog";
import AddTeamMemberAdminDialog from "./AddTeamMemberAdminDialog";
import useInviteService from "../../hooks/InviteService";
import EmailIcon from "@mui/icons-material/Email";
import ResendInviteDialog from "../dialogs/ResendInviteDialog";
import TaxyTooltip from "../TaxyTooltip";
import InviteExpiry from "../InviteExpiry";

type TeamUsersProps = {
  organisationId: string;
};

const TeamUsers = ({ organisationId }: TeamUsersProps) => {
  const userService = useUserService();
  const inviteService = useInviteService();
  const { setSystemMessage, userId } = useApplicationState();

  const [users, setUsers] = useState<UserType[]>([]);
  const [invites, setInvites] = useState<InviteType[]>([]);

  // menu state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuUser, setMenuUser] = useState<UserType>();
  const [menuInvite, setMenuInvite] = useState<InviteType>();
  const menuOpen = Boolean(anchorEl);

  // user dialogs
  const [addUserDialogOpen, setAddUserDialogOpen] = useState<boolean>(false);
  const [removeTeamMemberDialogOpen, setRemoveTeamMemberDialogOpen] = useState<boolean>(false);
  const [removeTeamMemberAdminDialogOpen, setRemoveTeamMemberAdminDialogOpen] = useState<boolean>(false);
  const [addTeamMemberAdminDialogOpen, setAddTeamMemberAdminDialogOpen] = useState<boolean>(false);

  // invite dialogs
  const [resendInviteDialogOpen, setResendInviteDialogOpen] = useState<boolean>(false);

  const isAdmin = useCallback(
    (user: UserType) => includes(user.adminOrganisationIds, organisationId),
    [organisationId]
  );

  const listUsersAndInvites = useCallback(async () => {
    const [userResults, inviteResults] = await Promise.all([
      userService.listAccountantUsers([organisationId]),
      inviteService.getAccountantInvites(organisationId),
    ]);

    setUsers(userResults);
    setInvites(inviteResults);
  }, [organisationId, userService, inviteService]);

  useEffect(() => {
    void listUsersAndInvites();
  }, [listUsersAndInvites]);

  const handleUserMenuClick = (event: MouseEvent<HTMLElement>, user: UserType) => {
    setMenuUser(user);
    setMenuInvite(undefined);
    setAnchorEl(event.currentTarget);
  };

  const handleInviteMenuClick = (event: MouseEvent<HTMLElement>, invite: InviteType) => {
    setMenuInvite(invite);
    setMenuUser(undefined);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddUserDialogOpen = () => {
    setAddUserDialogOpen(true);
  };

  const handleAddUserDialogClose = (message?: string) => {
    void listUsersAndInvites();
    setAddUserDialogOpen(false);
    setSystemMessage(message);
  };

  const handleRemoveTeamMemberDialogClosed = (userRemoved: boolean) => {
    if (userRemoved) {
      void listUsersAndInvites();
    }
    setRemoveTeamMemberDialogOpen(false);
  };

  const handleRemoveTeamMemberAdminDialogClose = (userUpdated: boolean) => {
    if (userUpdated) {
      void listUsersAndInvites();
    }
    setRemoveTeamMemberAdminDialogOpen(false);
  };

  const handleAddTeamMemberAdminDialogClose = (userUpdated: boolean) => {
    if (userUpdated) {
      void listUsersAndInvites();
    }
    setAddTeamMemberAdminDialogOpen(false);
  };

  const showRemoveUser = () => {
    setRemoveTeamMemberDialogOpen(true);
    handleMenuClose();
  };

  const showRemoveAdmin = () => {
    setRemoveTeamMemberAdminDialogOpen(true);
    handleMenuClose();
  };

  const showAddAdmin = () => {
    setAddTeamMemberAdminDialogOpen(true);
    handleMenuClose();
  };

  const showResendInvite = () => {
    setResendInviteDialogOpen(true);
    handleMenuClose();
  };

  const handleResendInviteDialogClose = (message?: string) => {
    if (message) {
      setSystemMessage(message);
      void listUsersAndInvites();
    }
    setResendInviteDialogOpen(false);
  };

  return (
    <Grid container sx={{ p: 2 }}>
      <Grid item xs={12} sx={{ my: 2 }}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6">Team members</Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <Button variant="contained" color="primary" startIcon={<PersonAddIcon />} onClick={handleAddUserDialogOpen}>
              Add team member
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TableContainer component={Paper} square elevation={0}>
          <Table aria-label="Users table">
            <TableHead>
              <TableRow>
                <TableCell>First name</TableCell>
                <TableCell>Last name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Administrator</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Invite expiry</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* USERS */}
              {orderBy(users, (user) => user.firstName).map((user) => (
                <TableRow key={user.uid}>
                  <TableCell>{user.firstName}</TableCell>
                  <TableCell>{user.lastName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{isAdmin(user) ? <CheckIcon color="success" /> : <CloseIcon color="error" />}</TableCell>
                  <TableCell sx={{ color: "success.main" }}>Accepted</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>
                    <TaxyTooltip
                      title={user.uid === userId ? "You cannot change your own membership status" : ""}
                      placement="left"
                    >
                      <Box>
                        <IconButton
                          onClick={(event) => handleUserMenuClick(event, user)}
                          disabled={user.uid === userId}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Box>
                    </TaxyTooltip>
                  </TableCell>
                </TableRow>
              ))}

              {/* INVITES */}
              {orderBy(invites, (invite) => invite.invitee.firstName).map((invite) => (
                <TableRow key={invite.id}>
                  <TableCell>{invite.invitee.firstName}</TableCell>
                  <TableCell>{invite.invitee.lastName}</TableCell>
                  <TableCell>{invite.invitee.email}</TableCell>
                  <TableCell>{invite.isAdmin ? <CheckIcon color="success" /> : <CloseIcon color="error" />}</TableCell>
                  <TableCell sx={{ color: invite.status === "PENDING" ? "warning.main" : "error.main" }}>
                    {invite.status === "PENDING" ? "Pending" : "Expired"}
                  </TableCell>
                  <TableCell>
                    <InviteExpiry invite={invite} />
                  </TableCell>
                  <TableCell>
                    <Box>
                      <IconButton onClick={(event) => handleInviteMenuClick(event, invite)}>
                        <MoreVertIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
          {menuUser && (
            <MenuItem dense onClick={() => showRemoveUser()}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Remove team member</ListItemText>
            </MenuItem>
          )}

          {menuUser && isAdmin(menuUser) && (
            <MenuItem dense onClick={() => showRemoveAdmin()}>
              <ListItemIcon>
                <RemoveModeratorIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Remove administrator rights</ListItemText>
            </MenuItem>
          )}

          {menuUser && !isAdmin(menuUser) && (
            <MenuItem dense onClick={() => showAddAdmin()}>
              <ListItemIcon>
                <VerifiedUserIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Make team administrator</ListItemText>
            </MenuItem>
          )}

          {menuInvite && (
            <MenuItem dense onClick={() => showResendInvite()}>
              <ListItemIcon>
                <EmailIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Resend invite email</ListItemText>
            </MenuItem>
          )}
        </Menu>
      </Grid>

      <AddTeamUserDialog organisationId={organisationId} open={addUserDialogOpen} onClose={handleAddUserDialogClose} />

      {menuUser && (
        <>
          <RemoveTeamMemberDialog
            organisationId={organisationId}
            user={menuUser}
            open={removeTeamMemberDialogOpen}
            onClose={handleRemoveTeamMemberDialogClosed}
          />

          <RemoveTeamMemberAdminDialog
            organisationId={organisationId}
            user={menuUser}
            open={removeTeamMemberAdminDialogOpen}
            onClose={handleRemoveTeamMemberAdminDialogClose}
          />

          <AddTeamMemberAdminDialog
            organisationId={organisationId}
            user={menuUser}
            open={addTeamMemberAdminDialogOpen}
            onClose={handleAddTeamMemberAdminDialogClose}
          />
        </>
      )}

      {menuInvite && (
        <ResendInviteDialog
          inviteId={menuInvite.id}
          open={resendInviteDialogOpen}
          onClose={handleResendInviteDialogClose}
        />
      )}
    </Grid>
  );
};

export default TeamUsers;
