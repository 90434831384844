export async function isPdfPasswordProtected(file: File): Promise<boolean> {
  if (file.type !== "application/pdf") {
    return false;
  }

  const arrayBuffer = await file.arrayBuffer();
  const text = new TextDecoder().decode(new Uint8Array(arrayBuffer));

  return text.includes("/Encrypt");
}
