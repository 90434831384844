import { ClientType } from "../../types";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useApplicationState } from "../../hooks/ApplicationState";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import useClientService from "../../hooks/ClientService";
import Box from "@mui/material/Box";

type ClientHeaderProps = {
  client: ClientType;
  showManageLink?: boolean;
  showBackLink?: boolean;
};

const ClientHeader = ({ client, showManageLink = false, showBackLink = false }: ClientHeaderProps) => {
  const { isAccountant } = useApplicationState();
  const clientService = useClientService();
  const navigate = useNavigate();

  return (
    <Grid
      container
      sx={{
        pt: 2,
        pb: 2,
        pl: 2,
        borderBottom: "1px solid",
        borderColor: "neutral.light",
        minHeight: 140,
      }}
    >
      <Grid item xs={12}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          <Box className="clientName">
            <Typography variant="h6" color="primary.main">
              Client
            </Typography>

            <Typography variant="h4">{client.name}</Typography>

            {showManageLink && isAccountant && (
              <Box>
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  aria-label="Manage client"
                  startIcon={<SettingsIcon />}
                  onClick={() =>
                    navigate(`${clientService.getClientUrl(client.organisationId, client.clientId)}/manage`)
                  }
                >
                  Manage client
                </Button>
              </Box>
            )}

            {showBackLink && (
              <Box>
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  aria-label="Back to client"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate(clientService.getClientUrl(client.organisationId, client.clientId))}
                >
                  Back to client
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ClientHeader;
