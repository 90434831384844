import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import SubmitButton from "../button/SubmitButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useUserService from "../../hooks/UserService";
import { useNavigate } from "react-router-dom";
import { useApplicationState } from "../../hooks/ApplicationState";

const formSchema = yup
  .object({
    email: yup.string().email("Email must be a valid email address").required("Email is required"),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

type RevertSecondFactorFormProps = {
  oobCode: string;
};

const RevertSecondFactorForm = ({ oobCode }: RevertSecondFactorFormProps) => {
  const { setSystemMessage } = useApplicationState();
  const userService = useUserService();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormType>({ resolver: yupResolver(formSchema) });

  const onSubmit = async (data: FormType) => {
    const { email } = data;
    try {
      await userService.revertMultiFactorAuth(oobCode, email);
      setSystemMessage("2-factor authentication has been turned off on your account.");
      reset();
      navigate("/login");
    } catch (e: any) {
      const message = "Error reverting 2-factor authentication, please check the link and try again";
      setError("email", { type: "custom", message });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h6">Revert 2-factor authentication</Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Confirm your email"
            error={!!errors.email}
            helperText={errors.email?.message}
            {...register("email")}
          />
        </Grid>

        <Grid item xs={12} textAlign="center">
          <SubmitButton loading={isSubmitting} label="Submit" />
        </Grid>
      </Grid>
    </form>
  );
};

export default RevertSecondFactorForm;
