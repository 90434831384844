import { useMemo } from "react";
import useFirestoreService, { FirestoreService } from "./FirestoreService";
import { SystemInfo } from "@taxy/common";
import { Unsubscribe } from "firebase/firestore";
import taxy from "../config/taxy.json";

export class SystemInfoService {
  constructor(readonly firestoreService: FirestoreService) {}

  public async isValidBuildNumber(): Promise<boolean> {
    const documentSnapshot = await this.firestoreService.getDocumentSnapshot(
      this.firestoreService.getSystemInfoDocument()
    );
    if (documentSnapshot.exists()) {
      const { buildNumber } = documentSnapshot.data() as SystemInfo;
      return buildNumber === taxy.buildNumber;
    } else {
      return false;
    }
  }

  public watchSystemInfo(callback: () => void): Unsubscribe {
    return this.firestoreService.watchDocument(this.firestoreService.getSystemInfoDocument(), callback);
  }
}

const useSystemInfoService = () => {
  const firestoreService = useFirestoreService();
  return useMemo(() => {
    return new SystemInfoService(firestoreService);
  }, [firestoreService]);
};

export default useSystemInfoService;
