import React from "react";
import useOrganisationService from "../../hooks/OrganisationService";
import { UserType } from "../../types";
import Typography from "@mui/material/Typography";
import { Box, DialogContentText } from "@mui/material";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";

type RemoveTeamMemberAdminDialogProps = {
  organisationId: string;
  user: UserType;
  open: boolean;
  onClose: (userUpdated: boolean) => void;
};

const RemoveTeamMemberAdminDialog = ({ organisationId, user, open, onClose }: RemoveTeamMemberAdminDialogProps) => {
  const organisationService = useOrganisationService();

  const handleRemoveUser = async () => {
    await organisationService.updateOrganisationAdminUser(organisationId, user.uid, false);
    onClose(true);
  };

  return (
    <ConfirmationDialog
      open={open}
      title="Remove administrator rights"
      content={
        <>
          <DialogContentText>
            Are you sure you want to remove the following user's administrator rights?
          </DialogContentText>

          <Box sx={{ my: 4 }}>
            <Typography variant="body1">
              {user.firstName} {user.lastName}
            </Typography>
            <Typography variant="body1">{user.email}</Typography>
          </Box>

          <DialogContentText>
            The user will remain a member of the team but will no longer be able to add/remove users or manage the
            catalogue.
          </DialogContentText>
        </>
      }
      confirmButtonLabel="Remove"
      cancelButtonLabel="Cancel"
      onClose={() => onClose(false)}
      onConfirm={handleRemoveUser}
      maxWidth="sm"
    />
  );
};

export default RemoveTeamMemberAdminDialog;
